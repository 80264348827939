<div
  *ngIf="filteredDailyReportsList.length; else noData"
  class="wrapper flex flex-col gap-[40px]"
>
  <ng-container *ngFor="let report of filteredDailyReportsList">
    <div class="each-report">
      <div
        (click)="selectReportId(report.id)"
        [class]="'header flex justify-between' + ' ' + report.status"
      >
        <div class="left">
          <div class="name text-type-5">
            {{ report?.createdBy?.first_name + ' ' + report?.createdBy?.last_name }}
          </div>
          <div class="filed-on text-type-10">
            {{ report?.created_at | date: 'hh:mm a on MMMM dd, YYYY' }}
          </div>
          <div class="status text-type-10">
            {{
              report.status === 'submitted' || report.status === 'reviewed' ?
                'Awaiting Approval' :
                report.statusText
            }}
          </div>
        </div>
        <div class="right">
          <mat-icon
            [svgIcon]="selectedReportId === report.id ? 'chevron-up' : 'chevron-down'"
            class="chevron"
          />
        </div>

      </div>
      <div
        *ngIf="selectedReportId === report.id"
        class="content"
      >
        <hr class="divider" />
        <app-daily-report-two />

        <hr *ngIf="report?.['next-actions']?.length > 0" class="divider" />
        <div *ngIf="report?.['next-actions']?.length > 0"
             class="work-flow-section min-h-[95px] flex flex-col justify-center items-center">
          <div class="form-section w-full mt-[20px]">
            <app-text-area
              [controlName]="formInputInfo?.name"
              [label]="formInputInfo?.label"
              [parentFormGroup]="form"
            />
          </div>
          <div
            *ngFor="let na of report?.['next-actions']"
            class="next-actions-section w-full flex flex-row-reverse gap-[20px]"
          >
            <app-primary-button
              (handleClick)="executeState(action)"
              *ngFor="let action of na?.actions"
              [color]="action?.options?.styles?.color"
              class="action-btn w-1/3 mb-[20px]"
            >
              {{ action?.options?.label }}
            </app-primary-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noData>
  <div class="no-data w-[711px]">
    No reports available for the selected date.
  </div>
</ng-template>
