import { createReducer, on } from '@ngrx/store';
import {
  CheckIfDailyReportCanBeCreatedFailure,
  CheckIfDailyReportCanBeCreatedRequest,
  CheckIfDailyReportCanBeCreatedSuccess,
  ClearBidSheet,
  ClearDailyReportRequest,
  ClearSitesListRequest,
  ClearState,
  FetchAllBidItemsFailure,
  FetchAllBidItemsRequest,
  FetchAllBidItemsSuccess,
  FetchBidItemListWithChangeOrderFailure,
  FetchBidItemListWithChangeOrderInSideNavFailure,
  FetchBidItemListWithChangeOrderInSideNavRequest,
  FetchBidItemListWithChangeOrderInSideNavSuccess,
  FetchBidItemListWithChangeOrderRequest,
  FetchBidItemListWithChangeOrderSuccess,
  FetchBidItemsListFailure,
  FetchBidItemsListRequest,
  FetchBidItemsListSuccess,
  FetchBidItemsSitesListFailure,
  FetchBidItemsSitesListRequest,
  FetchBidItemsSitesListSuccess,
  FetchBidSheetFailure,
  FetchBidSheetRequest,
  FetchBidSheetSuccess,
  FetchChangeOrderFilterFailure,
  FetchChangeOrderFilterInSideNavFailure,
  FetchChangeOrderFilterInSideNavRequest,
  FetchChangeOrderFilterInSideNavSuccess,
  FetchChangeOrderFilterRequest,
  FetchChangeOrderFilterSuccess,
  FetchDailyReportFailure,
  FetchDailyReportRequest,
  FetchDailyReportsByDateFailure,
  FetchDailyReportsByDateRequest,
  FetchDailyReportsByDateSuccess,
  FetchDailyReportSuccess,
  FetchEquipmentFilterFailure,
  FetchEquipmentFilterRequest,
  FetchEquipmentFilterSuccess,
  FetchIssueTypesFailure,
  FetchIssueTypesRequest,
  FetchIssueTypesSuccess,
  FetchLaborFilterFailure,
  FetchLaborFilterRequest,
  FetchLaborFilterSuccess,
  FetchNextActionStateFailure,
  FetchNextActionStateRequest,
  FetchNextActionStateSuccess,
  FetchPayappDetailsFailure,
  FetchPayappDetailsRequest,
  FetchPayappDetailsSuccess,
  FetchPayappItemsFailure,
  FetchPayappItemsRequest,
  FetchPayappItemsSuccess,
  FetchPayappsFailure,
  FetchPayappsRequest,
  FetchPayappsSuccess,
  FetchProjectDetailsFailure,
  FetchProjectDetailsRequest,
  FetchProjectDetailsSuccess,
  FetchProjectMembersFailure,
  FetchProjectMembersRequest,
  FetchProjectMembersSuccess,
  FetchProjectReportFailure,
  FetchProjectReportRequest,
  FetchProjectReportsListFailure,
  FetchProjectReportsListRequest,
  FetchProjectReportsListSuccess,
  FetchProjectReportSuccess,
  FetchProjectsListFailure,
  FetchProjectsListRequest,
  FetchProjectsListSuccess,
  FetchSiteDetailsFailure,
  FetchSiteDetailsRequest,
  FetchSiteDetailsSuccess,
  FetchSiteWiseDailyReportSummaryFailure,
  FetchSiteWiseDailyReportSummaryRequest,
  FetchSiteWiseDailyReportSummarySuccess,
  FetchSubContractorFilterFailure,
  FetchSubContractorFilterRequest,
  FetchSubContractorFilterSuccess,
  SetCurrentLocation,
  UpdatePayappStatusFailure,
  UpdatePayappStatusRequest,
  UpdatePayappStatusSuccess,
} from './projects.actions';

// State for this feature
export interface ProjectsState {
  currentLocation: any;

  projectsList: any;
  projectDetails: any;

  projectsFilterList: any[];
  sitesFilterData: any;
  projectMembersFilterList: any[];

  siteWiseDailyReportsSummary: any[];

  existingReportId: string;
  projectReportsList: any[];

  projectReport: any;
  payappItems: {};
  payapps: [];

  siteDetails: any;

  dailyReportsByDate: any[];

  dailyReport: any;
  bidSheet: any;
  bidItemsListInfo: {
    list: any,
    totalRecords: number,
  };
  bidItemsListInfoForSideNav: {
    list: any,
    totalRecords: number,
  };
  bidItemsSiteListInfo: {
    list: any,
    bidItemInfo?: any
  };
  changeOrderFilter: {
    list: any,
  };
  changeOrderFilterInSideNav: {
    list: any,
  };
  changeOrderFilterInContextWithBidItems: {
    list: any,
  };

  allBidItems: any[];

  allBidItemsFilter: any[];

  issueTypesLabelMap: any;

  issueTypesFilter: any[];

  subContractorFilter: any[];

  laborFilter: any[];

  equipmentFilter: any[];
}

const initialState: ProjectsState = {
  currentLocation: {
    latitude: null,
    longitude: null,
  },

  projectsList: [],

  projectDetails: {},

  projectsFilterList: [],

  siteWiseDailyReportsSummary: [],

  sitesFilterData: {},

  projectMembersFilterList: [],

  existingReportId: '',

  projectReportsList: [],

  projectReport: {},

  payappItems: {},

  payapps: [],

  siteDetails: {},

  dailyReportsByDate: [],

  dailyReport: {},

  allBidItems: [],

  allBidItemsFilter: [],

  bidSheet: {},

  bidItemsListInfo: {
    list: [],
    totalRecords: 0,
  },

  bidItemsListInfoForSideNav: {
    list: [],
    totalRecords: 0,
  },

  bidItemsSiteListInfo: {
    list: [],
    bidItemInfo: {},
  },

  changeOrderFilter: {
    list: [],
  },

  changeOrderFilterInSideNav: {
    list: [],
  },

  changeOrderFilterInContextWithBidItems: {
    list: [],
  },

  issueTypesLabelMap: {},

  issueTypesFilter: [],

  subContractorFilter: [],

  laborFilter: [],

  equipmentFilter: [],
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(ClearState, () => ({
    ...initialState,
  })),

  // CLEAR DAILY REPORT
  on(SetCurrentLocation, (state, props) => ({
    ...state,
    currentLocation: props.location,
  })),

  // CLEAR DAILY REPORT
  on(ClearDailyReportRequest, (state) => ({
    ...state,
    dailyReport: initialState.dailyReport,
  })),

  // FETCH PROJECTS LIST
  on(FetchProjectsListRequest, state => ({
    ...state,
    projectsList: initialState.projectsList,
  })),

  on(FetchProjectsListSuccess, (state, props) => ({
    ...state,
    ...props.payload,
  })),

  on(FetchProjectsListFailure, state => ({
    ...state,
    projectsList: initialState.projectsList,
  })),

  // FETCH PROJECT DETAILS
  on(FetchProjectDetailsRequest, state => ({
    ...state,
    projectDetails: initialState.projectDetails,
  })),

  on(FetchProjectDetailsSuccess, (state, props) => ({
    ...state,
    projectDetails: props.projectDetails,
  })),

  on(FetchProjectDetailsFailure, state => ({
    ...state,
    projectDetails: initialState.projectDetails,
  })),

  // FETCH SITE WISE DAILY REPORTS SUMMARY
  on(FetchSiteWiseDailyReportSummaryRequest, state => ({
    ...state,
    siteWiseDailyReportsSummary: initialState.siteWiseDailyReportsSummary,
  })),

  on(FetchSiteWiseDailyReportSummarySuccess, (state, props) => ({
    ...state,
    siteWiseDailyReportsSummary: props.siteWiseDailyReportsSummary,
  })),

  on(FetchSiteWiseDailyReportSummaryFailure, state => ({
    ...state,
    siteWiseDailyReportsSummary: initialState.siteWiseDailyReportsSummary,
  })),

  // FETCH PROJECT MEMBERS
  on(FetchProjectMembersRequest, state => ({
    ...state,
    projectMembersFilterList: initialState.projectMembersFilterList,
  })),

  on(FetchProjectMembersSuccess, (state, props) => ({
    ...state,
    projectMembersFilterList: props.projectMembersFilterList,
  })),

  on(FetchProjectMembersFailure, state => ({
    ...state,
    projectMembersFilterList: initialState.projectMembersFilterList,
  })),

  // CAN DAILY REPORT BE CREATED
  on(CheckIfDailyReportCanBeCreatedRequest, (state) => ({
    ...state,
    existingReportId: null,

  })),
  on(CheckIfDailyReportCanBeCreatedSuccess, (state, props) => ({
    ...state,
    existingReportId: props.existingReportId,
  })),
  on(CheckIfDailyReportCanBeCreatedFailure, (state) => state),

  // FETCH PROJECTS REPORTS LIST
  on(FetchProjectReportsListRequest, (state) => ({
    ...state,
    projectReportsList: initialState.projectReportsList,
  })),
  on(FetchProjectReportsListSuccess, (state, props) => ({
    ...state,
    projectReportsList: props.projectReportsList,
  })),
  on(FetchProjectReportsListFailure, (state) => state),

  // FETCH PROJECTS REPORT
  on(FetchProjectReportRequest, (state) => ({
    ...state,
    projectReport: initialState.projectReport,
  })),
  on(FetchProjectReportSuccess, (state, props) => ({
    ...state,
    projectReport: props.projectReport,
  })),
  on(FetchProjectReportFailure, (state) => state),

  // FETCH PAY APP ITEMS
  on(FetchPayappItemsRequest, (state) => ({
    ...state,
    payappItems: initialState.payappItems,
  })),

  on(FetchPayappItemsSuccess, (state, props) => {
    const newState = {
      ...state,
      payappItems: {
        ...state.payappItems,
        unpaid: props.payappItems,
      },
    };
    return newState;
  }),

  on(FetchPayappItemsFailure, (state) => state),

  // FETCH PAY APPS
  on(FetchPayappsRequest, (state) => ({
    ...state,
    payapps: initialState.payapps,
  })),
  on(FetchPayappsSuccess, (state, props) => ({
    ...state,
    payapps: props.payapps,
  })),
  on(FetchPayappsFailure, (state) => state),

  // FETCH PAY APP DETAILS
  on(FetchPayappDetailsRequest, state => state),
  on(FetchPayappDetailsSuccess, (state, props) => {
    const newState = {
      ...state,
      payappItems: {
        ...state.payappItems,
        [props.payappId]: Array.isArray(props.payappItems) ? props.payappItems : [], // Ensure it's stored as an array
      },
    };
    return newState;
  }),
  on(FetchPayappDetailsFailure, (state) => state),


  on(UpdatePayappStatusRequest, state => state),
  on(UpdatePayappStatusSuccess, (state, { data }) => {
    // Find the "unpaid" pay app list and update the item with the new status and comments
    const updatedPayappItems = { ...state.payappItems };
    if (updatedPayappItems['unpaid']) {
      updatedPayappItems['unpaid'] = updatedPayappItems['unpaid'].map(item =>
        item.report_bid_item_id === data.report_bid_item_id
          ? { ...item, pay_app_status: data.status, pay_app_comments: data.comments }
          : item,
      );
    }
    return {
      ...state,
      payappItems: updatedPayappItems,
    };
  }),
  on(UpdatePayappStatusFailure, (state) => state),


  // FETCH SITE DETAILS
  on(FetchSiteDetailsRequest, (state) => ({
    ...state,
    siteDetails: initialState.siteDetails,
  })),
  on(FetchSiteDetailsSuccess, (state, props) => ({
    ...state,
    siteDetails: props.siteDetails,
  })),
  on(FetchSiteDetailsFailure, (state) => state),

  // FETCH DAILY REPORTS BY DATE
  on(FetchDailyReportsByDateRequest, (state) => ({
    ...state,
    dailyReportsByDate: initialState.dailyReportsByDate,
  })),
  on(FetchDailyReportsByDateSuccess, (state, props) => ({
    ...state,
    dailyReportsByDate: props.dailyReportsByDate,
  })),
  on(FetchDailyReportsByDateFailure, (state) => state),

  // FETCH DAILY REPORT
  on(FetchDailyReportRequest, (state) => ({
    ...state,
    dailyReport: initialState.dailyReport,
  })),
  on(FetchDailyReportSuccess, (state, props) => ({
    ...state,
    dailyReport: props.dailyReport,
  })),
  on(FetchDailyReportFailure, (state) => state),

  // FETCH BID ITEMS
  on(FetchAllBidItemsRequest, (state) => ({
    ...state,
    allBidItems: initialState.allBidItems,
    allBidItemsFilter: initialState.allBidItemsFilter,
  })),
  on(FetchAllBidItemsSuccess, (state, props) => ({
    ...state,
    allBidItems: props.allBidItems,
    allBidItemsFilter: props.allBidItemsFilter,
  })),
  on(FetchAllBidItemsFailure, (state) => state),

  // CLEAR BID SHEET
  on(ClearBidSheet, state => ({
    ...state,
    bidSheet: initialState.bidSheet,
  })),

  // FETCH BID SHEET
  on(FetchBidSheetRequest, state => ({
    ...state,
    bidSheet: initialState.bidSheet,
  })),

  on(FetchBidSheetSuccess, (state, props) => ({
    ...state,
    bidSheet: props.bidSheet,
  })),

  on(FetchBidSheetFailure, state => ({
    ...state,
    bidSheet: initialState.bidSheet,
  })),

  // FETCH BID ITEMS LIST
  on(FetchBidItemsListRequest, state => ({
    ...state,
    ...initialState.bidItemsListInfo,
  })),

  on(FetchBidItemsListSuccess, (state, props) => ({
    ...state,
    bidItemsListInfo: {
      list: props.bidItemsListInfo.list,
      totalRecords: props.bidItemsListInfo.totalRecords,
    },
  })),

  on(FetchBidItemsListFailure, state => ({
    ...state,
    ...initialState.bidItemsListInfo,
  })),

  // FETCH BID ITEMS SITE LIST
  on(FetchBidItemsSitesListRequest, state => ({
    ...state,
    ...initialState.bidItemsSiteListInfo,
  })),

  on(FetchBidItemsSitesListSuccess, (state, props) => ({
    ...state,
    bidItemsSiteListInfo: props?.bidItemsSiteListInfo,
  })),

  on(FetchBidItemsSitesListFailure, state => ({
    ...state,
    ...initialState.bidItemsSiteListInfo,
  })),

  // FETCH CHANGE ORDER FILTERS
  on(FetchChangeOrderFilterRequest, state => ({
    ...state,
    ...initialState.changeOrderFilter,
  })),

  on(FetchChangeOrderFilterSuccess, (state, props) => ({
    ...state,
    changeOrderFilter: {
      list: props.list,
    },
  })),

  on(FetchChangeOrderFilterFailure, state => ({
    ...state,
    ...initialState.changeOrderFilter,
  })),

  // FETCH BID ITEM LIST WITH CHANGE ORDER
  on(FetchBidItemListWithChangeOrderRequest, state => ({
    ...state,
    ...initialState.bidItemsListInfo,
  })),

  on(FetchBidItemListWithChangeOrderSuccess, (state, props) => ({
    ...state,
    bidItemsListInfo: {
      list: props.bidItemsListInfo?.list,
      totalRecords: props.bidItemsListInfo?.totalRecords,
    },
  })),

  on(FetchBidItemListWithChangeOrderFailure, state => ({
    ...state,
    ...initialState.bidItemsSiteListInfo,
  })),

  // FETCH CHANGE ORDER FILTERS IN SIDE NAV
  on(FetchChangeOrderFilterInSideNavRequest, state => ({
    ...state,
    ...initialState.changeOrderFilterInSideNav,
  })),

  on(FetchChangeOrderFilterInSideNavSuccess, (state, props) => ({
    ...state,
    changeOrderFilterInSideNav: {
      list: props.list,
    },
  })),

  on(FetchChangeOrderFilterInSideNavFailure, state => ({
    ...state,
    ...initialState.changeOrderFilterInSideNav,
  })),

  // FETCH BID ITEM LIST WITH CHANGE ORDER IN SIDE NAV
  on(FetchBidItemListWithChangeOrderInSideNavRequest, state => ({
    ...state,
    ...initialState.bidItemsSiteListInfo,
  })),

  on(FetchBidItemListWithChangeOrderInSideNavSuccess, (state, props) => ({
    ...state,
    bidItemsSiteListInfo: props?.bidItemsSiteListInfo,
  })),

  on(FetchBidItemListWithChangeOrderInSideNavFailure, state => ({
    ...state,
    ...initialState.bidItemsSiteListInfo,
  })),

  // CLEAR SITES LIST
  on(ClearSitesListRequest, (state) => ({
    ...state,
    bidItemsSiteListInfo: initialState.bidItemsListInfo,
  })),

  // FETCH ISSUE TYPES
  on(FetchIssueTypesRequest, state => ({
    ...state,
    issueTypesLabelMap: initialState.issueTypesLabelMap,
    issueTypesFilter: initialState.issueTypesFilter,
  })),

  on(FetchIssueTypesSuccess, (state, props) => ({
    ...state,
    issueTypesLabelMap: props.issueTypesLabelMap,
    issueTypesFilter: props.issueTypesFilter,
  })),

  on(FetchIssueTypesFailure, state => ({
    ...state,
    issueTypesLabelMap: initialState.issueTypesLabelMap,
    issueTypesFilter: initialState.issueTypesFilter,
  })),

  // FETCH SUB CONTRACTOR FILTER
  on(FetchSubContractorFilterRequest, state => ({
    ...state,
    subContractorFilter: initialState.subContractorFilter,
  })),

  on(FetchSubContractorFilterSuccess, (state, props) => ({
    ...state,
    subContractorFilter: props.subContractorFilter,
  })),

  on(FetchSubContractorFilterFailure, state => ({
    ...state,
    subContractorFilter: initialState.subContractorFilter,
  })),

  // FETCH LABOR FILTER
  on(FetchLaborFilterRequest, state => ({
    ...state,
    laborFilter: initialState.laborFilter,
  })),

  on(FetchLaborFilterSuccess, (state, props) => ({
    ...state,
    laborFilter: props.laborFilter,
  })),

  on(FetchLaborFilterFailure, state => ({
    ...state,
    laborFilter: initialState.laborFilter,
  })),

  // FETCH EQUIPMENT FILTER
  on(FetchEquipmentFilterRequest, state => ({
    ...state,
    equipmentFilter: initialState.equipmentFilter,
  })),

  on(FetchEquipmentFilterSuccess, (state, props) => ({
    ...state,
    equipmentFilter: props.equipmentFilter,
  })),

  on(FetchEquipmentFilterFailure, state => ({
    ...state,
    equipmentFilter: initialState.equipmentFilter,
  })),


  // FETCH DAILY REPORT
  on(FetchNextActionStateRequest, (state) => ({
    ...state,
    dailyReportsByDate: initialState.dailyReportsByDate,
  })),
  on(FetchNextActionStateSuccess, (state, props) => ({
    ...state,
    dailyReportsByDate: props.dailyReport,
  })),
  on(FetchNextActionStateFailure, (state) => state),
);
