import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../state/app.state';
import { projectDetails } from '../../../../core/projects.selectors';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../../../shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class DailyReportService {

  triggerSave: Subject<any> = new Subject();
  save: Subject<any> = new Subject();

  addComment: Subject<any> = new Subject();
  disableAddMenu: Subject<any> = new Subject();
  disableOptionMenu: Subject<any> = new Subject();
  hideDeleteOption: Subject<any> = new Subject();
  fetchDailyReportById: Subject<any> = new Subject();
  issue: Subject<any> = new Subject();

  add: Subject<any> = new Subject();

  triggerDelete: Subject<any> = new Subject();
  delete: Subject<any> = new Subject();

  selectedLocation: Subject<any> = new Subject();

  constructor(
    private store: Store<fromRoot.State>,
    public dialog: MatDialog,
  ) {
  }

  formatAndSaveMultilineBidItemPayload(form, bidItem, lineItems) {
    const values = form.getRawValue();
    const lineItemsPayload = lineItems.map(lineItem => this._createBidItemPayload(bidItem, lineItem, values));

    let hasEmpty = false;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < lineItemsPayload.length; i++) {
      const lineItem = lineItemsPayload[i];

      if (lineItem.field_values) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < lineItem.field_values.length; j++) {
          if (!lineItem.quantity && lineItem.field_values[j].value === null) {
            hasEmpty = true;
            break;
          }
        }
      }

      if (hasEmpty) {
        break;
      }
    }

    if (hasEmpty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Empty line items',
          `There are empty entries, which will be removed if you continue. Do you want to continue?`,
          'Continue',
          'Cancel',
        ),
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          const cleanedLineItemsPayload = lineItemsPayload.filter(lineItem => {

            if (lineItem.field_values) {
              // tslint:disable-next-line:prefer-for-of
              for (let j = 0; j < lineItem.field_values.length; j++) {
                if (!lineItem.quantity && lineItem.field_values[j].value === null) {
                  return false;
                }
              }
            }

            return true;
          });

          this.save.next({
            type: 'bidItems',
            data: cleanedLineItemsPayload,
            options: {
              bidItem,
            },
          });
        }
      });
    } else {
      this.save.next({
        type: 'bidItems',
        data: lineItemsPayload,
        options: {
          bidItem,
        },
      });
    }
  }

  formatDeliveriesAndUsages(materialDeliveries) {
    const categorization = {
      deliveryBidItems: [],
      deliveryMaterials: [],
      deliveryBidItemMaterials: [],
      usageBidItems: [],
      usageMaterials: [],
      usageBidItemMaterials: [],
    };

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < materialDeliveries.length; i++) {
      const element = materialDeliveries[i];
      if (
        element.bid_item !== null &&
        element.material === null &&
        element.received === null &&
        element.used === null &&
        element.quantity > 0
      ) {
        categorization.deliveryBidItems.push(element);
        continue;
      }

      if (
        element.bid_item === null &&
        element.material !== null &&
        element.received !== null &&
        element.used === null &&
        element.quantity === 0
      ) {
        categorization.deliveryMaterials.push(element);
        continue;
      }

      if (
        element.bid_item !== null &&
        element.material !== null &&
        element.received !== null &&
        element.used === null &&
        element.quantity === 0
      ) {
        categorization.deliveryBidItemMaterials.push(element);
        continue;
      }

      // Add Header items to both the att bid items with materials
      if (
        element.bid_item !== null &&
        (element.material !== null || true) &&
        element.received === null &&
        element.used === null &&
        element.quantity === 0
      ) {
        categorization.deliveryBidItemMaterials.push(element);
        categorization.usageBidItemMaterials.push(element);
        continue;
      }

      if (
        element.bid_item !== null &&
        element.material === null &&
        element.received === null &&
        element.used !== null &&
        element.quantity === 0
      ) {
        categorization.usageBidItems.push(element);
        continue;
      }

      if (
        element.bid_item === null &&
        element.material !== null &&
        element.received === null &&
        element.used !== null &&
        element.quantity === 0
      ) {
        categorization.usageMaterials.push(element);
        continue;
      }

      if (
        element.bid_item !== null &&
        element.material !== null &&
        element.received === null &&
        element.used !== null &&
        element.quantity === 0
      ) {
        categorization.usageBidItemMaterials.push(element);
      }
    }

    const deliveryAndUsage = [];

    // tslint:disable-next-line:forin
    for (const key in categorization) {
      const eachCategoryData = categorization[key];
      deliveryAndUsage.push(...this._createDeliveryAndUsageBlocks(eachCategoryData, key));
    }

    return {
      deliveries: deliveryAndUsage.filter(o => o.type === 'delivery'),
      usages: deliveryAndUsage.filter(o => o.type === 'usage'),
    };
  }

  createNewDeliveryBlock(data) {
    let customFields: any[];
    this.store.select(projectDetails)
      .subscribe(project => {
        customFields = project.delivery_tracking_fields;
      }).unsubscribe();

    if (data.track_material_quantity !== undefined) {
      // This is a bid item
      if (data.materials.length) {
        // this is a bid item with materials
        return {
          id: `new_${new Date().getTime()}`,
          heading: data.item,
          subheading: data.description,
          latitude: null,
          longitude: null,
          pictures: [],
          comment: null,
          type: 'delivery',
          subType: 'bidItemWithMaterials',
          initialState: {
            bid_item: data,
            bid_item_id: data.id,
          },
          materials: [],
        };
      } else {
        // This is a bid item without materials
        return {
          id: `new_${new Date().getTime()}`,
          heading: data.item,
          subheading: data.description,
          uom: data.uom,
          quantity: null,
          fieldValues: customFields.map(o => ({ id: o.id, value: null })),
          latitude: null,
          longitude: null,
          pictures: [],
          comment: null,
          type: 'delivery',
          subType: 'onlyBidItem',
          initialState: {
            bid_item: data,
            bid_item_id: data.id,
          },
        };
      }
    } else {
      // This is material
      if (data.track_received) {
        return {
          id: `new_${new Date().getTime()}`,
          heading: data.name,
          uom: data.uom,
          quantity: null,
          fieldValues: customFields.map(o => ({ id: o.id, value: null })),
          latitude: null,
          longitude: null,
          pictures: [],
          comment: null,
          type: 'delivery',
          subType: 'onlyMaterial',
          initialState: { material: data },
        };
      }
    }
  }

  formatAndSaveDelivery(values, data, linesToDelete = []) {
    let customFields: any[];
    this.store.select(projectDetails)
      .subscribe(project => {
        customFields = project.delivery_tracking_fields;
      }).unsubscribe();

    let response = [];

    if (data.subType === 'onlyBidItem') {
      response = [
        {
          id: data.id,
          bid_item_id: data.initialState.bid_item_id,
          latitude: values[data.id].latitude,
          longitude: values[data.id].longitude,
          pictures: JSON.parse(values[data.id].pictures),
          quantity: values[data.id].quantity,
          received: null,
          used: null,
          field_values: customFields.map(o => ({ id: o.id, value: values[data.id]?.[o.id] || null })),
          comment: values[data.id].comment || '',
        },
      ];
    } else if (data.subType === 'onlyMaterial') {
      response = [
        {
          id: data.id,
          material_id: data.initialState.material.id,
          latitude: values[data.id]?.latitude,
          longitude: values[data.id].longitude,
          pictures: JSON.parse(values[data.id].pictures),
          received: values[data.id].quantity,
          quantity: 0,
          used: null,
          field_values: customFields.map(m => ({ id: m.id, value: values[data.id]?.[m.id] || null })),
          comment: values[data.id].comment || '',
        },
      ];
    } else if (data.subType === 'bidItemWithMaterials') {
      response = data.materials.map(o => ({
        id: o.id,
        bid_item_id: o.initialState.bid_item_id,
        material_id: o.initialState.material?.id,
        latitude: values[o.id]?.latitude,
        longitude: values[o.id].longitude,
        pictures: JSON.parse(values[o.id].pictures),
        received: values[o.id].quantity,
        quantity: 0,
        used: null,
        field_values: customFields.map(m => ({ id: m.id, value: values[o.id]?.[m.id] || null })),
        comment: values[o.id].comment || '',
      }));

      response.push({
        id: data.id,
        bid_item_id: data.initialState.bid_item_id,
        latitude: values[data.id]?.latitude,
        longitude: values[data.id].longitude,
        pictures: JSON.parse(values[data.id].pictures),
        quantity: 0,
        received: null,
        used: null,
        comment: values[data.id].comment || '',
      });
    }

    this.save.next({
      type: 'delivery',
      data: response,
      options: {
        linesToDelete,
      },
    });
  }

  createNewUsageBlock(data) {
    if (data.track_material_quantity !== undefined) {
      // This is a bid item
      if (data.materials.length) {
        // this is a bid item with materials
        return {
          id: `new_${new Date().getTime()}`,
          heading: data.item,
          subheading: data.description,
          latitude: null,
          longitude: null,
          pictures: [],
          comment: null,
          type: 'usage',
          subType: 'bidItemWithMaterials',
          initialState: {
            bid_item: data,
            bid_item_id: data.id,
          },
          materials: [],
        };
      } else {
        // This is a bid item without materials
        return {
          id: `new_${new Date().getTime()}`,
          heading: data.item,
          subheading: data.description,
          uom: data.uom,
          quantity: null,
          latitude: null,
          longitude: null,
          pictures: [],
          comment: null,
          type: 'usage',
          subType: 'onlyBidItem',
          initialState: {
            bid_item: data,
            bid_item_id: data.id,
          },
        };
      }
    } else {
      // This is material
      if (data.track_used) {
        return {
          id: `new_${new Date().getTime()}`,
          heading: data.name,
          uom: data.uom,
          quantity: null,
          latitude: null,
          longitude: null,
          pictures: [],
          comment: null,
          type: 'usage',
          subType: 'onlyMaterial',
          initialState: { material: data },
        };
      }
    }
  }

  formatAndSaveUsage(values, data, linesToDelete = []) {
    let response = [];

    if (data.subType === 'onlyBidItem') {
      response = [
        {
          id: data.id,
          bid_item_id: data.initialState.bid_item_id,
          latitude: values[data.id].latitude,
          longitude: values[data.id].longitude,
          pictures: JSON.parse(values[data.id].pictures),
          quantity: values[data.id].quantity,
          received: null,
          used: null,
          comment: values[data.id].comment || '',
        },
      ];
    } else if (data.subType === 'onlyMaterial') {
      response = [
        {
          id: data.id,
          material_id: data.initialState.material.id,
          latitude: values[data.id]?.latitude,
          longitude: values[data.id].longitude,
          pictures: JSON.parse(values[data.id].pictures),
          received: null,
          quantity: 0,
          used: values[data.id].quantity,
          comment: values[data.id].comment || '',
        },
      ];
    } else if (data.subType === 'bidItemWithMaterials') {
      response = data.materials.map(o => ({
        id: o.id,
        bid_item_id: o.initialState.bid_item_id,
        material_id: o.initialState.material.id,
        latitude: values[o.id]?.latitude,
        longitude: values[o.id].longitude,
        pictures: JSON.parse(values[o.id].pictures),
        received: null,
        quantity: 0,
        used: values[o.id].quantity,
        comment: values[o.id].comment || '',
      }));

      response.push({
        id: data.id,
        bid_item_id: data.initialState.bid_item_id,
        latitude: values[data.id]?.latitude,
        longitude: values[data.id].longitude,
        pictures: JSON.parse(values[data.id].pictures),
        quantity: 0,
        received: null,
        used: null,
        comment: values[data.id].comment || '',
      });
    }

    this.save.next({
      type: 'usage',
      data: response,
      options: {
        linesToDelete,
      },
    });
  }

  private _createBidItemPayload(bidItem, lineItem, values) {
    let payload = {
      ...lineItem,
      comment: values[lineItem.id].comment,
      pictures: JSON.parse(values[lineItem.id].pictures),
      latitude: values[lineItem.id].latitude,
      longitude: values[lineItem.id].longitude,
    };

    if (bidItem.rollup === false) {
      payload = {
        ...payload,
        quantity: values[lineItem.id].quantity ? Number(values[lineItem.id].quantity) : null,
      };
    } else if (bidItem.rollup === true) {
      const fieldValues = [];

      bidItem?.fields?.forEach((fieldValue) => {
        fieldValues.push({
          id: fieldValue.id,
          value: values?.[lineItem.id]?.[fieldValue.id] ? Number(values?.[lineItem.id]?.[fieldValue.id]) : null,
        });
      });

      payload = {
        ...payload,
        field_values: fieldValues,
      };

      if (bidItem.rollup_quantity_editable === true) {
        payload = {
          ...payload,
          quantity: values[lineItem.id].valueType === 'dynamic_entry' ?
            '' :
            values[lineItem.id].quantity && Number(values[lineItem.id].quantity) || null,
        };
      }
    }

    return payload;
  }

  private _createDeliveryAndUsageBlocks(list, type) {
    if (type === 'deliveryBidItems') {
      return list.map((item) => ({
        id: item.id,
        heading: item.bid_item.item,
        subheading: item.bid_item.description,
        uom: item.bid_item.uom,
        quantity: item.quantity,
        fieldValues: item.field_values,
        latitude: item.latitude,
        longitude: item.longitude,
        pictures: item.pictures,
        comment: item.comment,
        type: 'delivery',
        subType: 'onlyBidItem',
        initialState: item,
      }));
    }

    if (type === 'deliveryMaterials') {
      return list.map((item) => ({
        id: item.id,
        heading: item.material.name,
        uom: item.material.uom,
        quantity: item.received,
        fieldValues: item.field_values,
        latitude: item.latitude,
        longitude: item.longitude,
        pictures: item.pictures,
        comment: item.comment,
        type: 'delivery',
        subType: 'onlyMaterial',
        initialState: item,
      }));
    }

    if (type === 'deliveryBidItemMaterials') {
      const reduced = list.reduce((reducedValue, current) => {
        if (reducedValue[current.bid_item_id]) {
          reducedValue[current.bid_item_id].push(current);
        } else {
          reducedValue[current.bid_item_id] = [current];
        }

        return reducedValue;
      }, {});

      const resp = [];

      // tslint:disable-next-line:forin
      for (const key in reduced) {
        const data = reduced[key];

        const headerItemIndex = data.findIndex(o => o.bid_item && !o.material && !o.quantity && !o.received && !o.used);

        let headerItem: any;

        if (headerItemIndex > -1) {
          headerItem = (data.splice(headerItemIndex, 1))[0];
        } else {
          headerItem = {
            id: `new_${new Date().getTime()}`,
            bid_item: data[0].bid_item,
            bid_item_id: data[0].bid_item_id,
          };
        }

        const payload = {
          id: headerItem.id,
          heading: headerItem.bid_item.item,
          subheading: headerItem.bid_item.description,
          latitude: headerItem.latitude || null,
          longitude: headerItem.longitude || null,
          pictures: headerItem.pictures || [],
          comment: headerItem.comment || null,
          type: 'delivery',
          subType: 'bidItemWithMaterials',
          initialState: headerItem,

          materials: data.filter(o => o.quantity === 0 && o.received !== null && o.used === null)
            .map(item => ({
                id: item.id,
                heading: item.material?.name,
                uom: item.material?.uom,
                quantity: item.received,
                fieldValues: item.field_values,
                latitude: item.latitude,
                longitude: item.longitude,
                pictures: item.pictures,
                comment: item.comment,
                type: 'delivery',
                subType: 'onlyMaterial',
                initialState: item,
              }),
            ),
        };

        if (payload.materials.length) {
          resp.push(payload);
        }
      }

      return resp;
    }

    if (type === 'usageBidItems') {
      return list.map((item) => ({
        id: item.id,
        heading: item.bid_item.item,
        subheading: item.bid_item.description,
        uom: item.bid_item.uom,
        quantity: item.used,
        latitude: item.latitude,
        longitude: item.longitude,
        pictures: item.pictures,
        comment: item.comment,
        type: 'usage',
        subType: 'onlyBidItem',
        initialState: item,
      }));
    }

    if (type === 'usageMaterials') {
      return list.map((item) => ({
        id: item.id,
        heading: item.material.name,
        uom: item.material.uom,
        quantity: item.used,
        fieldValues: item.field_values,
        latitude: item.latitude,
        longitude: item.longitude,
        pictures: item.pictures,
        comment: item.comment,
        type: 'usage',
        subType: 'onlyMaterial',
        initialState: item,
      }));
    }

    if (type === 'usageBidItemMaterials') {
      const reduced = list.reduce((reducedValue, current) => {
        if (reducedValue[current.bid_item_id]) {
          reducedValue[current.bid_item_id].push(current);
        } else {
          reducedValue[current.bid_item_id] = [current];
        }

        return reducedValue;
      }, {});

      const resp = [];

      // tslint:disable-next-line:forin
      for (const key in reduced) {
        const data = reduced[key];

        const headerItemIndex = data.findIndex(o => o.bid_item && !o.material && !o.quantity && !o.received && !o.used);

        let headerItem: any;

        if (headerItemIndex > -1) {
          headerItem = (data.splice(headerItemIndex, 1))[0];
        } else {
          headerItem = {
            id: `new_${new Date().getTime()}`,
            bid_item: data[0].bid_item,
            bid_item_id: data[0].bid_item_id,
          };
        }

        const payload = {
          id: headerItem.id,
          heading: headerItem.bid_item.item,
          subheading: headerItem.bid_item.description,
          latitude: headerItem.latitude || null,
          longitude: headerItem.longitude || null,
          pictures: headerItem.pictures || [],
          comment: headerItem.comment || null,
          type: 'usage',
          subType: 'bidItemWithMaterials',
          initialState: headerItem,

          materials: data.filter(o => o.quantity === 0 && o.received === null && o.used !== null)
            .map(item => ({
                id: item.id,
                heading: item.material.name,
                uom: item.material.uom,
                quantity: item.used,
                fieldValues: item.field_values,
                latitude: item.latitude,
                longitude: item.longitude,
                pictures: item.pictures,
                comment: item.comment,
                type: 'usage',
                subType: 'onlyMaterial',
                initialState: item,
              }),
            ),
        };

        if (payload.materials.length) {
          resp.push(payload);
        }
      }

      return resp;
    }

    return [];
  }
}
