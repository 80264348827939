import { Component, ComponentFactory, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Issue, IssueType, Project } from 'src/app/shared/models';
import { ProjectService } from 'src/app/shared/services';
import { IssueService } from '../../issue.service';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ProjectsService } from '../../../projects/core/projects.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  IssueDetailsWrapperComponent,
} from '../../../projects/pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/issue-details/issue-details-wrapper/issue-details-wrapper.component';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../state/app.state';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FetchIssueTypesRequest, FetchProjectDetailsRequest } from '../../../projects/core/projects.actions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../../../../material/material.css'],
  animations: [
    trigger('rightAnimation', [
      state(
        '*',
        style({
          width: '0px',
        }),
      ),
      state(
        'sidenavOpen',
        style({
          width: '535px',
        }),
      ),
      state(
        'sidenavClosed',
        style({
          width: '0px',
        }),
      ),
      transition('*=>sidenavOpen', animate('500ms')),
      transition('* => void, * => sidenavClosed', animate('500ms')),
    ]),
    trigger('legendAnimation', [
      state(
        'visible',
        style({
          transform: 'translate(0%, 0%) scale(1)',
          opacity: 1,
        }),
      ),
      state(
        'void, hidden',
        style({
          transform: 'translate(-30%, 25%) scale(0.4)',
          opacity: 0,
        }),
      ),
      transition('* => visible', animate('250ms')),
      transition('* => void, * => hidden', animate('250ms')),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container;
  isFullScreen = false;
  showSidenav = false;
  public loading = 0;
  public initialized = false;
  public projectId: string;
  public project: Project;
  public entityType: string;
  public id: string;
  public issues: Issue[] = [];
  public filteredIssues: Issue[] = [];
  public scopeList: any[] = [];
  public filteredScope: any[] = [];
  public currentContext: string;
  issueTypes: IssueType[];
  selectedIssueId = '';
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 10000,
  };
  mapOptions: any = {
    zoom: 16,
    height: '300px',
  };
  statusOptions = [
    {
      key: 'open',
      label: 'Open',
    },
    {
      key: 'closed',
      label: 'Closed',
    },
  ];
  form = new FormGroup({
    searchText: new FormControl({ value: '', disabled: false }),
    scope: new FormControl({ value: '', disabled: false }),
    scopeFilterCtrl: new FormControl({ value: '', disabled: false }),
    categories: new FormArray([]),
    status: new FormArray([
      new FormControl(false),
      new FormControl(false),
    ]),
  });
  issuesScopeOptions: any[];
  dailyReport: any;
  protected readonly Issue = Issue;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private issueSrv: IssueService,
    private projectSrv: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private issueService: IssueService,
    private projectsService: ProjectsService,
    private resolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    private store: Store<fromRoot.State>,
  ) {
  }

  get categoriesFormArray() {
    return this.form.controls.categories as FormArray;
  }

  get statusFormArray() {
    return this.form.controls.status as FormArray;
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.has('projectId')) {
      this.projectId = this.route.snapshot.paramMap.get('projectId');
      this.fetchProject(this.projectId);

      this.store.dispatch(FetchIssueTypesRequest({
        payload: {
          projectId: this.route.snapshot.params['projectId'],
        },
      }));

      this.store.dispatch(FetchProjectDetailsRequest({
        payload: {
          projectId: this.route.snapshot.params.projectId,
          qp: {
            include: [
              'cover_photo',
              'owner_organization',
              'delivery_tracking_fields',
              'materials',
              {
                bid_items: [
                  'rollup_fields',
                  'materials',
                  {
                    fields: ['recent_fields'],
                  },
                  {
                    headings: [
                      'stations',
                      'alignment',
                    ],
                  },
                ],
              },
              {
                internal_tests: [
                  'bid_item_ids',
                  'site_ids',
                  'fields',
                ],
              },
              {
                sites: [
                  'delivery_tracking_fields',
                  {
                    headings: ['stations'],
                  },
                ],
              },
            ],
          },
        },
      }));
    }

    const { e: entityType, i: id } = this.route.snapshot.queryParams;
    this.currentContext = (entityType && id) ? `${entityType}/${id}` : `project/${this.projectId}`;

    // subscribe to query params change, to refetch on filter changes
    this.route.queryParamMap.pipe(switchMap(params => of(params)))
      .subscribe(params => {
        if (params.get('e') && params.get('i')) {
          const context = `${params.get('e')}/${params.get('i')}`;
          if (this.initialized) {
            this.currentContext = context;
            this.pagination.pageIndex = 0;
            this.fetchRecords(this.project, this.pagination);
          }
        }
      });

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.status) {
          let status: any = qp.status;

          if (!Array.isArray(status)) {
            status = [status];
          }

          if (status.includes('open')) {
            this.form.controls.status.controls[0].setValue(true);
          } else {
            this.form.controls.status.controls[0].setValue(false);
          }

          if (status.includes('closed')) {
            this.form.controls.status.controls[1].setValue(true);
          } else {
            this.form.controls.status.controls[1].setValue(false);
          }

          this.filterIssues();
        }
      });

    this.projectSrv.fetchIssues
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchRecords(this.project, {
          pageIndex: 0,
          length: 0,
          pageSize: 1000,
        });
      });

    this.issueService.fetchIssuesList
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchRecords(this.project, {
          pageIndex: 0,
          length: 0,
          pageSize: 1000,
        });
      });

    this.form.controls.scope.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(scope => {
        this.currentContext = scope;
        const [e, i] = scope.split('/');
        this.router.navigate(['./'], { relativeTo: this.route, queryParams: { e, i } });
      });

    this.form.controls.searchText.valueChanges
      .pipe(
        debounceTime(1000),
        takeUntil(this.onDestroy),
      )
      .subscribe(() => {
        this.filterIssues();
      });

    this.form.controls.scopeFilterCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        takeUntil(this.onDestroy),
      )
      .subscribe((text) => {
        this.filteredScope = this.scopeList;

        this.filteredScope = this.filteredScope.filter(o => o.label.toLowerCase().includes(text.toLowerCase()));
      });

    this.form.controls.status.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        const status = [];

        if (d[0]) {
          status.push('open');
        }

        if (d[1]) {
          status.push('closed');
        }

        this.router.navigate([], {
          queryParams: {
            status,
          },
        });
      });

    this.form.controls.categories.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterIssues();
      });

    this.projectsService.openRightPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        this.showSidenav = true;
        setTimeout(() => {
          this.createComponent(params?.component, params?.data);
        }, 300);
      });

    this.projectsService.closeRightPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.router.navigate([], {
          queryParams: {
            selectedId: null,
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          this.showSidenav = false;
          this.container?.clear();
        });
      });
  }

  createComponent(component, data) {
    this.container?.clear();

    const factory: ComponentFactory<any> = this.resolver?.resolveComponentFactory(component);
    const componentRef = this.container?.createComponent(factory);
    componentRef.instance.data = data;
  }

  fetchProject(id: string) {
    this.loading++;
    const qp = {
      include: ['sites', 'cover_photo', 'owner_organization'],
    };
    this.projectSrv.getRecord(id, qp).toPromise()
      .then((project: Project) => {
        this.project = project;

        this.scopeList = [
          {
            value: `project/${this.project.id}`,
            label: `Project ${project.number} ${project.name}`,
          },
          ...this.project.sites.map(o => ({
            value: `site/${o.id}`,
            label: o.name,
          })),
        ];

        this.issuesScopeOptions = [
          {
            value: `${this.project.id}`,
            label: `Project ${project.name}`,
            type: 'project',
          },
          ...this.project.sites.map(o => ({
            value: `${o.id}`,
            label: `Site ${o.name}`,
            type: 'site',
          })),
        ];

        this.filteredScope = this.scopeList;

        this.setMapOptions(this.project);
        this.fetchIssueTypes(this.project);
        return this.fetchRecords(this.project, this.pagination);
      })
      .catch(e => {
        // redirect to 403/404
        console.error(e);
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Fetches IssueType[]
   * @param project Project
   */
  fetchIssueTypes(project: Project): void {
    this.projectSrv.getIssueTypes(project)
      .then((issueTypes: IssueType[]) => {
        this.issueTypes = issueTypes;
        this.issueTypes.forEach(() => this.categoriesFormArray.push(new FormControl(false)));
      });
  }


  fetchRecords(project: Project, pagination: any) {
    this.loading++;

    const [e, i] = this.currentContext.split('/');
    const qp: any = {};
    if (e !== 'project') {
      qp.entity_type = e;
      qp.entity_id = i;
    }

    this.issueSrv.fetchRecords(project, pagination, qp)
      .then((resp: any) => {
        this.issues = resp.result;
        this.pagination.length = resp.meta?.totalRecords ?? 0;

        this.filterIssues();
      })
      .catch(err => {
        // redirect to 403/404
        console.error(err);
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords(this.project, this.pagination);
  }

  openIssue(instance: Issue) {
    setTimeout(() => {
      this.projectsService.openRightPanel.next({
        component: IssueDetailsWrapperComponent,
        data: {
          ...instance,
        },
      });
    });
  }

  createIssue() {
    setTimeout(() => {
      this.projectsService.openRightPanel.next({
        component: IssueDetailsWrapperComponent,
      });
    });
  }

  setMapOptions(project) {
    this.mapOptions.center = { lat: project.latitude, lng: project.longitude };
    this.mapOptions.markers = [];
    this.mapOptions.markers.push({
      lat: project.latitude,
      lng: project.longitude,
      title: project.name,
    });
    project.sites.map((s) => {
      this.mapOptions.markers.push({
        lat: s.latitude,
        lng: s.longitude,
        title: s.name,
      });
    });
  }

  filterIssues() {
    this.loading++;

    this.filteredIssues = this.issues;

    const {
      searchText,
      status,
      categories,
    } = this.form.getRawValue();

    const statusValues = status.map((s, index) => {
      if (s) {
        return this.statusOptions[index].key;
      }
    }).filter(d => !!d);

    const categoryValues = categories.map((s, index) => {
      if (s) {
        return this.issueTypes[index].type;
      }
    }).filter(d => !!d);

    if (searchText.length) {
      this.filteredIssues = this.filteredIssues.filter((issue) => {
        if (issue.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          issue.description?.toLowerCase()
            .includes(searchText.toLowerCase())) {
          return issue;
        }
      });
    }

    if (statusValues.length) {
      let issues = [];
      statusValues.map((o) => {
        const tempStatusIssues = this.filteredIssues.filter(issue => issue.status === o);
        issues = [
          ...issues,
          ...tempStatusIssues,
        ];
      });
      this.filteredIssues = issues.sort((a, b) => {
        const lastUpdatedA = new Date(a.updated_at).getTime();
        const lastUpdatedB = new Date(b.updated_at).getTime();

        return lastUpdatedB - lastUpdatedA;
      });
    }

    if (categoryValues.length) {
      let issues = [];
      categoryValues.map((o) => {
        const tempCategoryIssues = this.filteredIssues.filter(issue => issue.type === o);
        issues = [
          ...issues,
          ...tempCategoryIssues,
        ];
      });

      this.filteredIssues = issues;
    }

    this.loading--;
  }

  close() {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();
    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.projectsService.closeRightPanel.next(null);
          this.projectsService.isAnyFormDirty.next(false);
        }
      });
    } else {
      this.projectsService.closeRightPanel.next(null);
      this.projectsService.isAnyFormDirty.next(false);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
