<div class="wrapper pt-[40px] flex flex-col gap-[40px] flex-1">
  <app-project-reports-list *ngIf="projectReportsList.length"/>
<!--  <app-horizontal-date-picker-->
<!--    [parentFormGroup]="form"-->
<!--    controlName="date"-->
<!--    class="date-picker-row"-->
<!--  />-->
  <app-project-consolidated-report
    *ngIf="projectReport && Object.keys(projectReport).length > 0; else
    noReport"
    class="w-[771px]"
    [editable]="false"
    [dailyReports]="projectReport?.dailyReports || []"
    [showConsolidatedReport]="true"
  />
  <ng-template #noReport>
    <div class="no-data flex justify-start items-center">
      No consolidated report for this day.
    </div>
  </ng-template>
  <!-- Show workflow actions for project report -->
  <ng-container *ngIf="projectReport?.id && ('read' | able: projectReport)">
    <app-workflow
      wf="project-report-approval"
      [id]="projectReport.id"
      [projectId]="projectId"
      [redraw]="projectReport.status"
      (actionResponse)="projectReportChanged($event)"
    />
  </ng-container>
</div>
