import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppService} from '../../../services';

@Component({
  selector: 'app-pictures-section',
  templateUrl: './pictures-section.component.html',
  styleUrls: ['./pictures-section.component.scss']
})
export class PicturesSectionComponent {
  @Input() pictures: any[] = [];
  @Input() isEditable = true;
  @Input() maxHeight?: string; 
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  constructor(
    public appService: AppService,
  ) {
  }

  deletePicture(id) {
    this.onDelete.emit(id);
  }
}
