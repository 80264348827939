import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {DailyReportService} from '../../../../daily-report.service';
import {AppService} from '../../../../../../../../../../shared/services';

@Component({
  selector: 'app-usage-details-form',
  templateUrl: './usage-details-form.component.html',
  styleUrls: ['../../input-component-styles.scss', './usage-details-form.component.scss']
})
export class UsageDetailsFormComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() form: FormGroup = new FormGroup({});
  @Input() selectedPictures: any[] = [];
  @Input() uom: string;
  @Output() onDeletePicture: EventEmitter<void> = new EventEmitter();

  disableAddMenu = false;

  constructor(
    public appService: AppService,
  ) {
  }

  ngOnInit() {
  }

  deletePicture(id) {
    this.onDeletePicture.emit(id);
  }

  deleteLocation() {
    this.form?.get('latitude').setValue(null);
    this.form?.get('longitude').setValue(null);
  }

  showLocationSection() {
    return this.form?.controls?.latitude?.value !== null &&
      this.form?.controls?.longitude?.value !== null &&
      this.form?.controls?.latitude?.value !== '' &&
      this.form?.controls?.longitude?.value !== '';
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
