<div class="wrapper">
  <app-text-input
    [parentFormGroup]="form"
    class="mb-[20px]"
    controlName="title"
    label="title"
  />
  <app-text-area
    [parentFormGroup]="form"
    class="mb-[20px]"
    controlName="description"
    label="Description"
  />
  <app-dropdown
    [enableSearch]="true"
    [options]="projectScopeOptions"
    [parentFormGroup]="form"
    class="mb-[20px]"
    controlName="projectScope"
    label="Project Scope"
    placeholder="Search"
  />
  <app-dropdown
    (checkForErrors)="checkForErrors($event)"
    [enableDetailedOptionView]="true"
    [enableSearch]="true"
    [error]="errorMessages['bid']"
    [options]="bidItemOptions"
    [parentFormGroup]="form"
    class="mb-[20px]"
    controlName="bidItem"
    label="Bid Item"
    placeholder="Search"
  />
  <div class="flex flex-row justify-between items-center mb-[40px] gap-[20px] w-full">
    <div class="label text-type-13 ">
      Internal:
    </div>
    <mat-slide-toggle
      (change)="onToggle($event)"
      [checked]="form?.controls?.internal?.value"
      [disableRipple]="false"
      class="slide-toggle-btn"
      color="primary"
    >
    </mat-slide-toggle>
  </div>
  <app-button-select
    (checkForErrors)="checkForErrors($event)"
    [error]="errorMessages['type']"
    [options]="categoryOptions"
    [parentFormGroup]="form"
    class="mb-[40px]"
    controlName="type"
    label="category"
  />
  <app-pictures-section
    (onDelete)="deletePicture($event)"
    *ngIf="pictures?.length"
    [pictures]="pictures"
    class="pictures-section mb-[40px]"
  />
  <app-location-section
    (onDelete)="deleteLocation()"
    *ngIf="showLocationSection()"
    [latitude]="form?.controls?.latitude?.value"
    [longitude]="form?.controls?.longitude?.value"
    class="location-section mb-[40px]"
  />
</div>
