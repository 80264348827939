import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AppService } from '../../../../../../../../../../shared/services';

@Component({
  selector: 'app-delivery-details-form',
  templateUrl: './delivery-details-form.component.html',
  styleUrls: ['../../input-component-styles.scss', './delivery-details-form.component.scss'],
})
export class DeliveryDetailsFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup = new FormGroup({});
  @Input() selectedPictures: any[] = [];
  @Input() customFields: any[] = [];
  @Input() uom: string;
  @Output() onDeletePicture: EventEmitter<void> = new EventEmitter();
  disableAddMenu = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    public appService: AppService,
  ) {
  }

  ngOnInit() {
  }

  deletePicture(id) {
    this.onDeletePicture.emit(id);
  }

  deleteLocation() {
    this.form?.get('latitude').setValue(null);
    this.form?.get('longitude').setValue(null);
  }

  showLocationSection() {
    return this.form?.controls?.latitude?.value !== null &&
      this.form?.controls?.longitude?.value !== null &&
      this.form?.controls?.latitude?.value !== '' &&
      this.form?.controls?.longitude?.value !== '';
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
