<div class="wrapper">
  <div
    *ngIf="filteredProjectReportsList?.length"
    class="reports-list"
    [class.collapsed]="!isExpanded"
  >
    <app-project-reports-card
      class="each-project-report-card"
      *ngFor="let report of filteredProjectReportsList; let i = index"
      [report]="report"
      (click)="selectReport(report, i)"
    />
  </div>
  <div
    *ngIf="projectReportsList?.length > 3"
    class="see-more text-type-9 flex"
    (click)="handleExpansion()"
  >
    {{ isExpanded ? 'See less' : 'See more' }}
  </div>
</div>
