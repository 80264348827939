import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-map-wrapper',
  templateUrl: './map-wrapper.component.html',
  styleUrls: ['./map-wrapper.component.scss']
})
export class MapWrapperComponent implements OnDestroy{
  private readonly onDestroy: Subject<any> = new Subject<any>();
  isFullScreen = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;
      });
  }

  zoomIn() {

  }

  zoomOut() {

  }

  closeMap() {
    this.router.navigate([], {
      queryParams: {}
    });
  }

  openInFullScreen() {
    this.router.navigate([], {
      queryParams: {
        fs: 1
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
