<div class="wrapper" [style.max-height]="maxHeight">
  <div class="pictures-section-label">PICTURE:</div>
  <div class="pictures-row flex flex-wrap gap-[20px]">
    <ng-container *ngFor="let picture of pictures">
      <div class="picture-block">
        <mat-icon
          class="delete-picture"
          [class.editable]="isEditable"
          svgIcon="trash"
          (click)="isEditable && deletePicture(picture.id)"
        />
        <app-image
          class="each-picture"
          [url]="picture.thumb_url"
          (click)="appService.openCarousel($event, pictures, picture)"
        />
      </div>
    </ng-container>
  </div>
</div>
