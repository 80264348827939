import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PayappItem, Project } from 'src/app/shared/models';
import { Subject, of } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, filter, switchMap, tap, take, debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import * as fromRoot from 'src/app/state/app.state';
import { projectDetails, selectPayappItems, selectPayapps } from 'src/app/modules/projects/core/projects.selectors';
import { 
  FetchPayappItemsRequest, 
  GeneratePayappRequest, 
  FetchPayappsRequest, 
  FetchPayappDetailsRequest, 
  FetchPayappDetailsSuccess, 
  DeletePayappRequest, 
  DeletePayappSuccess,
  UpdatePayappStatusRequest,
  AddContractorStatusRequest,
  AddContractorStatusSuccess,
} from 'src/app/modules/projects/core/projects.actions';
import { AppService, AuthService} from 'src/app/shared/services';
import { CommonService } from 'src/app/shared/services/common.service';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { FormControl } from '@angular/forms';
import { CreatePayappDialogComponent } from '../../dialogs/create-payapp-dialog/create-payapp-dialog.component';
import { PayappCommentsDialogComponent } from '../../dialogs/payapp-comments-dialog/payapp-comments-dialog.component';
import { AddReasonComponent } from '../../dialogs/add-reason/add-reason.component';
import { PayappDateFilterComponent } from '../../filters/payapp-date-filter/payapp-date-filter.component';
import { PayappMultiFilterComponent } from '../../filters/payapp-multi-filter/payapp-multi-filter.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { report } from 'process';

@Component({
  selector: 'app-pay-app',
  templateUrl: './pay-app.component.html',
  styleUrls: ['./pay-app.component.scss'],
})
export class PayappComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  public projectDetails: any;
  
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('dateFilter') dateFilterComponent: PayappDateFilterComponent; 
  @ViewChild('multiFilter') multiFilterComponent: PayappMultiFilterComponent;
  @ViewChild('searchInputFull') searchInputFull: ElementRef; 
  
  public loading = 0;
  public downloading = false;
  public initialized = false;

  @Input()

  payappItems: PayappItem[] = [];
  payapps: any [];
  defaultDateValue = 'All time';
  selectedPayapp: any = null; // Default selection
  unpaidPayapp = { id: 'unpaid', name: 'Unpaid', start_date: null, end_date: null };
  contractorStatusList = [
    { key: 1, value: 'Agree' },
    { key: 0, value: 'Disagree' },
  ];


  public displayedItems: PayappItem[] = [];
  public totalRecords: number;
  public totalAmount: number = 0;
  public statusMap: { [report_bid_item_id: string]: string } = {};
  public tempStatusMap: { [report_bid_item_id: string]: string } = {};
  public commentsMap: { [report_bid_item_id: string]: string } = {};
  public contractorStatusMap: { [report_bid_item_id: string]: string } = {};
  public tempContractorStatusMap: { [report_bid_item_id: string]: string } = {};
  public contractorCommentsMap: { [report_bid_item_id: string]: string } = {};
  public contractorSuggestedQuantityMap: { [report_bid_item_id: string]: number } = {};


  public searchControl = new FormControl('');  

  public pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  }

  currentUser: any;

  // Define the displayedColumns array
  displayedColumns: string[] = ['Date', 'Site', 'Created By','Bid Item', 'Quantity', 'Amount', 'Status'];
  sortByColumns: string[] = ['Date', 'Site', 'Bid Item', 'Status', 'Quantity', 'Amount', 'Created By'];
  columnPropertyMap: { [key: string]: string } = {
    'Date': 'report_date',
    'Site': 'site_name',
    'Created By': 'created_by',
    'Bid Item': 'item',
    'Quantity': 'bid_item_quantity',
    'Amount': 'amount',
    'Status': 'pay_app_status'
  };

  payappItemsMap = new Map<string, PayappItem>();
  startDate = null;
  endDate = null;
  readOnly: boolean;
  filters: any = null;
  selectedSortColumn: string = null;
  sortDirection: 'asc' | 'desc' = 'asc';
  isSearchActive: boolean = false;
  isContractorMode: boolean = false;


  constructor(
    private store: Store<fromRoot.State>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private appSrv: AppService,
    private authService: AuthService,
    private actions: Actions,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.currentUser = this.authService?.getCurrentUser() || {};
  }

  ngOnInit(): void {

    this.route.queryParams.pipe(
      filter(params => params.tab === 'payapp' && !this.initialized),
      switchMap(() => this.store.select(projectDetails).pipe(
        tap(data => {
          if (Object.keys(data).length !== 0) {
            this.projectDetails = data;
            if (!this.selectedPayapp) {
              this.selectedPayapp = this.unpaidPayapp;
            }
            //Set contractor mode
            this.isContractorMode = this.currentUser.roles.some(r => r.project_id === this.projectDetails?.id && (r.role === 'contractor-pm' || r.role === 'contractor-inspector'));
            this.onPayappChange(this.selectedPayapp);
            this.fetchPayappItems(this.selectedPayapp.id);
            this.fetchPayapps();
          }
        })
      )),
      takeUntil(this.onDestroy)
    ).subscribe(() => {
      // Subscribe to selectPayappItems
      this.store.select(selectPayappItems).pipe(
        filter((items: any) => !!items && Object.keys(items).length > 0),
        takeUntil(this.onDestroy)
      ).subscribe((items: any) => {
        const prevPayappItems = this.payappItems;
        this.payappItems = items[this.selectedPayapp?.id] || [];
        
        this.payappItems = this.applyFilters(this.payappItems);
        const filteredPrevPayappItems = this.applyFilters(prevPayappItems);        
        
        const resetPagination = !(this.payappItems && this.payappItems.length === prevPayappItems?.length);

        this.initializePayappItems(resetPagination);
        this.initialized = true;
      });
    
      // Subscribe to selectPayapps
      this.store.select(selectPayapps).pipe(
        takeUntil(this.onDestroy)
      ).subscribe((payapps: any[]) => {
        this.payapps = [this.unpaidPayapp, ...payapps];
      });
    });

    // Search control value changes subscription
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        this.filterDisplayedItems();
    });

    //Switch to new payapp after save
    this.actions.pipe(
      ofType(FetchPayappDetailsSuccess),
      takeUntil(this.onDestroy)
    ).subscribe((action) => {
      if (action.type === FetchPayappDetailsSuccess.type) {
        const generatedPayapp = this.payapps.find(payapp => payapp.id === action.payappId);
        if (generatedPayapp) {
          this.selectedPayapp = generatedPayapp;
          this.onPayappChange(this.selectedPayapp);
          this.resetFilters();
        }
      }
    });

    this.actions.pipe(
      ofType(AddContractorStatusSuccess),
      takeUntil(this.onDestroy)
    ).subscribe((action) => {
      if (action.type === AddContractorStatusSuccess.type) {
        this.fetchPayappItems(this.selectedPayapp.id);
        this.initializeMaps();
      }
    });

    
    //Switch to unpaid after delete 
    this.actions.pipe(
      ofType(DeletePayappSuccess),
      takeUntil(this.onDestroy)
    ).subscribe((action) => {
      if (action.type === DeletePayappSuccess.type) {
        this.selectedPayapp = this.unpaidPayapp;
        this.onPayappChange(this.selectedPayapp);
        this.resetFilters();
      }
    });


    this.commonService.closeDialog
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.dialog.closeAll();
    });

  }
  
  fetchPayappItems(payappId: string) {
    const qp = {
      ...(this.startDate && { startDate: this.startDate }),
      ...(this.endDate && { endDate: this.endDate }),
      includeDeferred: true,
      includeRejected: true,
    };
  
    if (payappId === 'unpaid') {
      this.store.dispatch(FetchPayappItemsRequest({
        payload: {
          projectId: this.projectDetails?.id,
          qp,
        },
      }));
    }
    else if (payappId) {
      this.store.dispatch(FetchPayappDetailsRequest({
        payload: {
          projectId: this.projectDetails?.id,
          payappId: payappId,
          qp: {
            include: ['bid_items'],
          },
        }
      }));
    }
    return of(null);
  }

  get hasReadyItems(): boolean {
    return this.payappItems && this.payappItems.some(item => this.statusMap[item.report_bid_item_id] === 'ready');
  }
  
  fetchPayapps() {
    this.store.dispatch(FetchPayappsRequest({
      payload: {
        projectId: this.projectDetails?.id,
      },
    }));
    return of(null);
  }

  initializePayappItems(resetPagination: boolean): void {
    this.initializeMaps();
    if (resetPagination) {
      this.pagination = {
        pageIndex: 0,
        pageSize: 10,
        length: this.payappItems?.length
      };
    }

    this.payappItems = this.sortItems(this.payappItems);
    this.calculateTotalAmount();
    this.filterDisplayedItems();
  }

  // ON change sort column
  onSortColumnChange(event: any): void {
    this.selectedSortColumn = event.value;
    this.sortDirection = 'asc'; 
    this.filterDisplayedItems();
  }

  // ON toggle sort direction
  toggleSortDirection(): void {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.filterDisplayedItems();
  }

  sortItems(items: any[]): any[] {
    if (!items || !Array.isArray(items)) {
      return [];
    }
  
    const itemsToSort = [...items];
  
    // Default sort functions for each column
    const sortFns = {
      'Date': (a, b) => a.report_date.localeCompare(b.report_date),
      'Site': (a, b) => a.site_name.localeCompare(b.site_name),
      'Created By': (a, b) => a.created_by.localeCompare(b.created_by),
      'Bid Item': (a, b) => a.item.localeCompare(b.item),
      'Quantity': (a, b) => a.quantity - b.quantity,
      'Amount': (a, b) => (a.quantity * a.unit_price) - (b.quantity * b.unit_price),
      'Status': (a, b) => {
          const statusA = this.isContractorMode ? (a.contractor_status || 'agree') : (a.pay_app_status || 'ready');
          const statusB = this.isContractorMode ? (b.contractor_status || 'agree') : (b.pay_app_status || 'ready');
          return statusA.localeCompare(statusB);
        },    
    };

    // Handle sort direction for selected column
    const applySortDirection = (result) => {
      return this.sortDirection === 'asc' ? result : -result;
    };
  
    // First, sort by the selected column if any
    if (this.selectedSortColumn && sortFns[this.selectedSortColumn]) {
      itemsToSort.sort((a, b) => {
        const result = sortFns[this.selectedSortColumn](a, b);
        if (result !== 0) return applySortDirection(result); // Only apply selected column sort direction if values are different

        const defaultSortOrder = ['Date', 'Site', 'Bid Item', 'Quantity', 'Amount', 'Status'].filter(col => col !== this.selectedSortColumn);

        for (const col of defaultSortOrder) {
          const secondaryResult = sortFns[col](a, b);
          if (secondaryResult !== 0) return secondaryResult; 
        }
        return 0; 
      });
    } else {
      // If no selected column, just apply default sorting
      const defaultSortOrder = ['Date', 'Site', 'Bid Item', 'Quantity', 'Amount', 'Status'];

      itemsToSort.sort((a, b) => {
        for (const col of defaultSortOrder) {
          const result = sortFns[col](a, b);
          if (result !== 0) return result; // Return as soon as a default sort has different values
        }
        return 0;
      });
    }  
    return itemsToSort;
  }

  removeSort(event: Event): void {
    event?.stopPropagation();
    this.selectedSortColumn = null;
    this.filterDisplayedItems();
  }

  onSearchClick(event: Event): void {
    event?.stopPropagation();
    this.isSearchActive = true;  // Activates full search mode
    setTimeout(() => this.searchInputFull?.nativeElement.focus(), 0); 
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.isSearchActive = false;
    setTimeout(() => this.searchInput?.nativeElement.blur(), 0);
  }
  

  // Initialize maps
  initializeMaps(): void {
    this.payappItems?.forEach(item => {
      this.payappItemsMap.set(item.report_bid_item_id, item);
      this.statusMap[item.report_bid_item_id] = item.pay_app_status || 'ready';
      this.commentsMap[item.report_bid_item_id] = item.pay_app_comments || '';
      this.contractorStatusMap[item.report_bid_item_id] = item.contractor_status || 'agree';
      this.contractorCommentsMap[item.report_bid_item_id] = item.contractor_comments || '';
      this.contractorSuggestedQuantityMap[item.report_bid_item_id] = item.contractor_suggested_quantity || null;
    });
  }

  resetFilters(): void {
    if (this.dateFilterComponent) {
      this.dateFilterComponent.removeDateFilter(null); 
    }

    if (this.multiFilterComponent) {
      this.multiFilterComponent.resetFilters();
    }

    //reset search filter 
  }

  onStatusChange(reportBidItemId: string, newStatus: string): void {

    // Check if the status change requires a comment
    if (newStatus === 'deferred' || newStatus === 'rejected') {
        const previousStatus = this.statusMap[reportBidItemId]; 
        this.tempStatusMap[reportBidItemId] = newStatus; 
        this.openCommentsDialog(reportBidItemId, previousStatus); 
    } else {
        // Directly update status if no dialog is needed
        this.statusMap[reportBidItemId] = newStatus;
        this.tempStatusMap[reportBidItemId] = newStatus;
        this.commentsMap[reportBidItemId] = '';
        this.updatePayappStatus(reportBidItemId);
        this.calculateTotalAmount();
    }
  }

  onContractorStatusChange(reportBidItemId: string, newStatus: string): void {
    // Check if the status change requires a comment
    if (newStatus === 'disagree') {
        const previousContractorStatus = this.contractorStatusMap[reportBidItemId]; 
        this.tempContractorStatusMap[reportBidItemId] = newStatus; 
        this.openContractorStatusDialog(reportBidItemId, previousContractorStatus); 
    } else {
        // Directly update status if no dialog is needed
        this.contractorStatusMap[reportBidItemId] = newStatus;
        this.tempContractorStatusMap[reportBidItemId] = newStatus;
        this.contractorCommentsMap[reportBidItemId] = '';
        this.contractorSuggestedQuantityMap[reportBidItemId] = null;
        this.updateContractorStatusToAgreed(reportBidItemId);
    }
  }

  openCommentsDialog(reportBidItemId: string, previousStatus: string): void {
    const dialogRef = this.dialog.open(PayappCommentsDialogComponent, {
      data: { comments: this.commentsMap[reportBidItemId] || '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) { 
        this.commentsMap[reportBidItemId] = result;
        this.statusMap[reportBidItemId] = this.tempStatusMap[reportBidItemId]; // Commit the status change
        this.calculateTotalAmount();
        // Dispatch the action to update status and comments
        this.updatePayappStatus(reportBidItemId);
      } else { 
        delete this.tempStatusMap[reportBidItemId]; // Discard the temporary status
        // Revert to the previous status
        this.statusMap[reportBidItemId] = previousStatus;
      }
    });
  }

  openContractorStatusDialog(reportBidItemId: string, previousStatus: string): void {
    const selectedItem = this.payappItems.find(item => item.report_bid_item_id === reportBidItemId);
    const data = {
      dailyReportId: selectedItem.daily_report_id,
      id: reportBidItemId,
      contractor_comments: selectedItem.contractor_comments,
      contractor_status: selectedItem.contractor_status,
      contractor_suggested_quantity: selectedItem.contractor_suggested_quantity,
      contractor_pictures: selectedItem.contractor_pictures || [],
    };
    const dialogRef = this.dialog.open(AddReasonComponent, {
      disableClose: false,
      data: { x: 3, selectedRowData: data },
      panelClass: 'floating-dialog-box',
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) { 
        this.contractorCommentsMap[reportBidItemId] = result?.comments;
        this.contractorStatusMap[reportBidItemId] = this.tempContractorStatusMap[reportBidItemId]; // Commit the status change
      } else { 
        delete this.tempContractorStatusMap[reportBidItemId]; 
        // Revert to the previous status
        if (previousStatus) {
          this.contractorStatusMap[reportBidItemId] = previousStatus;
        }
      }
    });
  }


  updatePayappStatus(reportBidItemId: string): void {
    // Dispatch the action to update status and comments
    this.store.dispatch(UpdatePayappStatusRequest({
      payload: {
        dailyReportId: this.payappItems.find(item => item.report_bid_item_id === reportBidItemId).daily_report_id,
        reportBidItemId: reportBidItemId,
        data: {
          status: this.statusMap[reportBidItemId],
          comments: this.commentsMap[reportBidItemId]
        }
      }
    }));
  }

  updateContractorStatusToAgreed(reportBidItemId: string): void {
    const selectedItem = this.payappItems.find(item => item.report_bid_item_id === reportBidItemId);
    const payload = {
      comments: '',
      suggested_quantity: null,
      reportBidItemId: reportBidItemId,
      dailyReportId: selectedItem.daily_report_id,
      status: 'agree',
    };
  
    this.store.dispatch(AddContractorStatusRequest({ payload }));

  }

  onDateChanged(dates: { startDate: string | null, endDate: string | null }): void {
    this.startDate = dates.startDate;
    this.endDate = dates.endDate;
    this.resetPayappItems();
  }
  

  onPayappChange(selectedPayapp): void {

    this.selectedPayapp = selectedPayapp;
    this.readOnly = this.selectedPayapp?.id !== 'unpaid';
    this.displayedColumns = ['Date', 'Site', 'Created By', 'Bid Item', 'Quantity', 'Amount', ...(!this.readOnly ? ['Status'] : [])];
    this.sortByColumns = ['Date', 'Site', 'Bid Item', ...(!this.readOnly ? ['Status'] : []), 'Quantity', 'Amount', 'Created By'];

    this.store.select(selectPayappItems).pipe(
        take(1)
      ).subscribe(items => {
        const existingItems = items[selectedPayapp?.id];
        if (existingItems) {
          this.payappItems = existingItems;  
          this.initializePayappItems(true);
        } else {
          this.fetchPayappItems(this.selectedPayapp?.id);
        }
    });
    //reset date filters when switching payapps
    this.onDateChanged({ startDate: null, endDate: null }); 
  }

  resetPayappItems(): void {
      //Reset payappitems filtered by date range selected
      this.store.select(selectPayappItems).pipe(
        filter((items: any) => !!items && Object.keys(items).length > 0),
        takeUntil(this.onDestroy)
      ).subscribe((items: any) => {

        const prevPayappItems = this.payappItems;
        this.payappItems = items[this.selectedPayapp?.id] || [];
        
        this.payappItems = this.applyFilters(this.payappItems);
        
        const resetPagination = !(this.payappItems && this.payappItems.length === prevPayappItems?.length);
        this.initializePayappItems(resetPagination);
      });

  }

  applyFilters = (items) => items.filter(item => {
    const reportDate = moment(item.report_date);
    const dateMatch = (!this.startDate || reportDate.isSameOrAfter(this.startDate, 'day')) &&
                      (!this.endDate || reportDate.isSameOrBefore(this.endDate, 'day'));
  
    if (!dateMatch) return false;
  
    if (this.filters) {
      const { status, contractorStatus, site, creator } = this.filters;
  
      // Filter by status
      const statusMatch = !status?.length || status.includes(item.pay_app_status ?? 'ready');

      // Filter by contractor status
      const contractorStatusMatch = !contractorStatus?.length || contractorStatus.includes(item.contractor_status ?? 'agree');

      // Filter by site
      const siteMatch = !site?.length || site.includes(item.site_id);
  
      // Filter by creator
      const creatorMatch = !creator?.length || creator.includes(item.created_by_id);
  
      // Return true if the item matches all filters
      return statusMatch && contractorStatusMatch && siteMatch && creatorMatch;
    }
  
    return true;
  });


  onFiltersChanged(filters): void {
    this.filters = filters;
    this.resetPayappItems();
  }


  confirmDeletePayapp(): void {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Recall payapp',
        `Are you sure you want to recall this payapp?`,
        'Recall',
        'Cancel'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      // Dispatch the delete action here
      this.store.dispatch(DeletePayappRequest({
        payload: {
          projectId: this.projectDetails?.id,
          payappId: this.selectedPayapp.id
        }
      }));

    });
  }

  calculateTotalAmount(): void {
    this.totalAmount = this.payappItems?.reduce((total, item) =>
      (this.statusMap[item.report_bid_item_id] === 'ready' || 
        this.statusMap[item.report_bid_item_id] === 'paid')
        ? total + (item.quantity * item.unit_price) 
        : total, 0);
  }

  filterDisplayedItems(): void {
    if (!this.payappItems) {
      return;
    }
    const searchTermLower = this.searchControl.value?.toLowerCase() || '';
    this.displayedItems = this.sortItems(this.payappItems)
      .filter(item => {
        const transformedItem = {
          ...item,
          pay_app_status: item.pay_app_status === null ? 'Ready' : item.pay_app_status,
          report_date: moment(item.report_date).format('MMM D, YYYY')
        };

        return Object.values(transformedItem).some(val => 
          String(val).toLowerCase().includes(searchTermLower)
        );
      })
      .slice(this.pagination.pageIndex * this.pagination.pageSize, 
             (this.pagination.pageIndex + 1) * this.pagination.pageSize);

  }

  pageChanged(page: number) {
    if (!this.initialized) {
      return;
    }
    this.pagination.pageIndex = page - 1;
    this.filterDisplayedItems();
  }

  generatePayapp(): void {
    const dialogRef = this.dialog.open(CreatePayappDialogComponent, {
      data: {
        itemCount: this.payappItems.length,
        startDate: this.startDate,
        endDate: this.endDate,
        totalAmount: this.totalAmount,
        payappname: '',
      },
    });
  
    dialogRef.afterClosed().pipe(
      filter(payappName => payappName !== undefined),
      tap(payappName => {
        const itemMaps = {
          status: this.statusMap,
          comments: this.commentsMap
        }
        const payloadData = PayappItem.toPayload(this.payappItems, itemMaps, payappName);
        const payload = {
          projectId: this.projectDetails.id, // Include projectId
          ...payloadData,
          payappName: payappName,
          start_date: this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
          end_date: this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
        };

        this.store.dispatch(GeneratePayappRequest({  
          payload: {
            projectId: this.projectDetails?.id,
            data: payload
          },
        }));
      })
    ).subscribe();
  }
  
  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
