import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Graphic from '@arcgis/core/Graphic';
import esriConfig from '@arcgis/core/config';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import Basemap from '@arcgis/core/Basemap';
// import Popup from '@arcgis/core/widgets/Popup';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import CIMSymbol from '@arcgis/core/symbols/CIMSymbol';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import Collection from '@arcgis/core/core/Collection.js';
import { environment as ENV } from 'src/environments/environment.beta';

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.scss'],
})
export class EsriMapComponent implements OnInit {
  @Input() center: number[];
  @Input() zoom: number;
  @Input() polygons: any[];
  @Input() polylines: any[];
  @Input() markers: any[];
  @Input() allowMarkerClick = false; // Default marker will not show
  // pointer. Only in fullscreen mode with clicking be allowed

  // @Output() mapLoaded = new EventEmitter<__esri.Map>();
  @Output() mapComponentLoaded = new EventEmitter<EsriMapComponent>();
  @Output() mapLoaded = new EventEmitter<__esri.Map>();
  @Output() viewLoaded = new EventEmitter<__esri.MapView>();
  @Output() markerClick = new EventEmitter<any>();
  @Output() markerHover = new EventEmitter<any>();

  @ViewChild('mapViewNode', { static: true }) private mapViewNode: ElementRef;
  private mapInstance: __esri.Map;
  private mapViewInstance: __esri.MapView;
  private mapProjectGroupLayer: __esri.GroupLayer;
  private mapSiteMarkersGroupLayer: __esri.GroupLayer;
  private mapSiteStationsGroupLayer: __esri.GroupLayer;
  private mapProjectGraphicsLayer: __esri.GraphicsLayer;
  private mapSiteMarkersGraphicsLayer: __esri.GraphicsLayer;
  private mapStationMarkersGraphicsLayer: __esri.GraphicsLayer;
  private readonly mapMarkerStencils: any;

  constructor() {
    this.mapMarkerStencils = {
      project: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/project.svg',
        width: '22px',
        height: '30px',
      },
      site: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/site.svg',
        width: '20px',
        height: '20px',
      },
      bid_items: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/bid_items.svg',
        width: '26px',
        height: '36px',
      },
      activities: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/activities.svg',
        width: '26px',
        height: '36px',
      },
      deliveries: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/deliveries.svg',
        width: '26px',
        height: '36px',
      },
      issues: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/issues.svg',
        width: '26px',
        height: '36px',
      },
      quality_test: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/quality_test.svg',
        width: '26px',
        height: '36px',
      },
      labor_equipment: {
        type: 'picture-marker',
        url: '/assets/icons/map/markers/labor_equipment.svg',
        width: '26px',
        height: '36px',
      },
      alignment: {
        type: 'picture-marker',
        url: '/assets/icons/svg/dot.svg',
        width: '4px',
        height: '4px',
      },
    };
  }

  addGhostLocationsStencil(count: string) {
    this.mapMarkerStencils.ghost_locations = new CIMSymbol({
      data: {
        type: 'CIMSymbolReference',
        symbol: {
          type: 'CIMPointSymbol',
          symbolLayers: [
            {
              type: 'CIMVectorMarker',
              enable: true,
              anchorPointUnits: 'Relative',
              dominantSizeAxis3D: 'Z',
              size: 26,
              billboardMode3D: 'FaceNearPlane',
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 80,
                ymax: 40,
              },
              markerGraphics: [
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    rings: [
                      [
                        [60, 39],
                        [63, 38.8],
                        [65.9, 38.1],
                        [68.6, 36.9],
                        [71.2, 35.4],
                        [73.4, 33.4],
                        [75.4, 31.2],
                        [76.9, 28.6],
                        [78.1, 25.9],
                        [78.8, 23],
                        [79, 20],
                        [78.6, 16.3],
                        [77.6, 12.7],
                        [75.8, 9.4],
                        [73.4, 6.6],
                        [71.2, 4.6],
                        [68.6, 3.1],
                        [65.9, 1.9],
                        [63, 1.2],
                        [60, 1],
                        [20, 1],
                        [16.3, 1.4],
                        [12.7, 2.4],
                        [9.4, 4.2],
                        [6.6, 6.6],
                        [4.6, 8.8],
                        [3.1, 11.4],
                        [1.9, 14.1],
                        [1.2, 17],
                        [1, 20],
                        [1.2, 23],
                        [1.9, 25.9],
                        [3.1, 28.6],
                        [4.6, 31.2],
                        [6.6, 33.4],
                        [8.8, 35.4],
                        [11.4, 36.9],
                        [14.1, 38.1],
                        [17, 38.8],
                        [20, 39],
                        [60, 39],
                      ],
                    ],
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Butt',
                        joinStyle: 'Miter',
                        // @ts-ignore
                        lineStyle3D: 'Strip',
                        miterLimit: 4,
                        width: 2,
                        // @ts-ignore
                        height3D: 1,
                        anchor3D: 'Center',
                        color: [16, 54, 214, 255],
                      },
                      {
                        type: 'CIMSolidFill',
                        enable: true,
                        color: [255, 255, 255, 255],
                      },
                    ],
                    angleAlignment: 'Map',
                  },
                },
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    paths: [
                      [
                        [29.33, 27.52],
                        [28.56, 27.94],
                        [27.74, 28.25],
                        [26.88, 28.44],
                        [26, 28.5],
                        [24.86, 28.4],
                        [23.77, 28.09],
                        [22.76, 27.58],
                        [21.88, 26.9],
                        [21.15, 26.07],
                        [20.61, 25.12],
                        [20.28, 24.1],
                        [20.17, 23.03],
                        [20.25, 22.34],
                        [20.48, 21.52],
                        [21.42, 19.46],
                      ],
                      [
                        [31.81, 22.67],
                        [31.64, 21.87],
                        [31.3, 20.92],
                        [30.12, 18.61],
                        [28.29, 15.76],
                        [26, 12.67],
                        [25.07, 13.65],
                        [24.06, 14.96],
                      ],
                      [
                        [33.5, 27.92],
                        [19.91, 14.33],
                      ],
                    ],
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Round',
                        joinStyle: 'Round',
                        // @ts-ignore
                        lineStyle3D: 'Strip',
                        miterLimit: 4,
                        width: 2,
                        // @ts-ignore
                        height3D: 1,
                        anchor3D: 'Center',
                        color: [16, 54, 214, 255],
                      },
                    ],
                    angleAlignment: 'Map',
                  },
                },
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    x: 38,
                    y: 16,
                  },
                  symbol: {
                    type: 'CIMTextSymbol',
                    blockProgression: 'TTB',
                    countryISO: 'USA',
                    depth3D: 1,
                    drawSoftHyphen: true,
                    extrapolateBaselines: true,
                    fontEffects: 'Normal',
                    fontEncoding: 'Unicode',
                    fontFamilyName: 'Montserrat',
                    fontStyleName: 'Bold',
                    fontType: 'TTOpenType',
                    haloSize: 1,
                    height: 14,
                    hinting: 'Default',
                    horizontalAlignment: 'Left',
                    kerning: true,
                    languageISO: 'eng',
                    letterWidth: 100,
                    ligatures: true,
                    lineGapType: 'ExtraLeading',
                    symbol: {
                      type: 'CIMPolygonSymbol',
                      symbolLayers: [
                        {
                          type: 'CIMSolidFill',
                          enable: true,
                          color: [16, 54, 214, 255],
                        },
                      ],
                      // @ts-ignore
                      angleAlignment: 'Map',
                    },
                    textCase: 'Normal',
                    textDirection: 'LTR',
                    verticalAlignment: 'Baseline',
                    verticalGlyphOrientation: 'Right',
                    wordSpacing: 100,
                    billboardMode3D: 'FaceNearPlane',
                  },
                  textString: `${count}`,
                },
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true,
              clippingPath: {
                type: 'CIMClippingPath',
                clippingType: 'Intersect',
                path: {
                  rings: [
                    [
                      [0, 0],
                      [80, 0],
                      [80, 40],
                      [0, 40],
                      [0, 0],
                    ],
                  ],
                },
              },
            },
          ],
        },
      },
    });
  }

  addGroupedLocationsStencil(count: number) {
    this.mapMarkerStencils.grouped_locations = new CIMSymbol({
      data: {
        type: 'CIMSymbolReference',
        symbol: {
          type: 'CIMPointSymbol',
          symbolLayers: [
            {
              type: 'CIMVectorMarker',
              enable: true,
              anchorPointUnits: 'Relative',
              dominantSizeAxis3D: 'Z',
              size: 40,
              billboardMode3D: 'FaceNearPlane',
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 40,
                ymax: 40,
              },
              markerGraphics: [
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    rings: [
                      [
                        [19, 2],
                        [22.7, 2.4],
                        [26.3, 3.4],
                        [29.6, 5.2],
                        [32.4, 7.6],
                        [34.8, 10.4],
                        [36.6, 13.7],
                        [37.6, 17.3],
                        [38, 21],
                        [37.6, 24.7],
                        [36.6, 28.3],
                        [34.8, 31.6],
                        [32.4, 34.4],
                        [29.6, 36.8],
                        [26.3, 38.6],
                        [22.7, 39.6],
                        [19, 40],
                        [15.3, 39.6],
                        [11.7, 38.6],
                        [8.4, 36.8],
                        [5.6, 34.4],
                        [3.2, 31.6],
                        [1.4, 28.3],
                        [0.4, 24.7],
                        [0, 21],
                        [0.4, 17.3],
                        [1.4, 13.7],
                        [3.2, 10.4],
                        [5.6, 7.6],
                        [8.4, 5.2],
                        [11.7, 3.4],
                        [15.3, 2.4],
                        [19, 2],
                      ],
                    ],
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidFill',
                        enable: true,
                        color: [16, 54, 214, 255],
                      },
                    ],
                    // @ts-ignore
                    angleAlignment: 'Map',
                  },
                },
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    rings: [
                      [
                        [19, 2],
                        [22.7, 2.4],
                        [26.3, 3.4],
                        [29.6, 5.2],
                        [32.4, 7.6],
                        [34.8, 10.4],
                        [36.6, 13.7],
                        [37.6, 17.3],
                        [38, 21],
                        [37.6, 24.7],
                        [36.6, 28.3],
                        [34.8, 31.6],
                        [32.4, 34.4],
                        [29.6, 36.8],
                        [26.3, 38.6],
                        [22.7, 39.6],
                        [19, 40],
                        [15.3, 39.6],
                        [11.7, 38.6],
                        [8.4, 36.8],
                        [5.6, 34.4],
                        [3.2, 31.6],
                        [1.4, 28.3],
                        [0.4, 24.7],
                        [0, 21],
                        [0.4, 17.3],
                        [1.4, 13.7],
                        [3.2, 10.4],
                        [5.6, 7.6],
                        [8.4, 5.2],
                        [11.7, 3.4],
                        [15.3, 2.4],
                        [19, 2],
                      ],
                    ],
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Round',
                        joinStyle: 'Round',
                        // @ts-ignore
                        lineStyle3D: 'Strip',
                        miterLimit: 4,
                        width: 2,
                        // @ts-ignore
                        height3D: 1,
                        anchor3D: 'Center',
                        color: [101, 131, 255, 255],
                      },
                    ],
                    angleAlignment: 'Map',
                  },
                },
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    x: 20,
                    y: 15.514000000000001,
                  },
                  symbol: {
                    type: 'CIMTextSymbol',
                    blockProgression: 'TTB',
                    countryISO: 'USA',
                    depth3D: 1,
                    drawSoftHyphen: true,
                    extrapolateBaselines: true,
                    fontEffects: 'Normal',
                    fontEncoding: 'Unicode',
                    fontFamilyName: 'Montserrat',
                    fontStyleName: 'Bold',
                    fontType: 'TTOpenType',
                    haloSize: 1,
                    height: 14,
                    size: 22,
                    hinting: 'Default',
                    horizontalAlignment: 'Center',
                    kerning: true,
                    languageISO: 'eng',
                    letterWidth: 100,
                    ligatures: true,
                    lineGapType: 'ExtraLeading',
                    symbol: {
                      type: 'CIMPolygonSymbol',
                      symbolLayers: [
                        {
                          type: 'CIMSolidFill',
                          enable: true,
                          color: [255, 255, 255, 255],
                        },
                      ],
                      // @ts-ignore
                      angleAlignment: 'Map',
                    },
                    textCase: 'Normal',
                    textDirection: 'LTR',
                    verticalAlignment: 'Baseline',
                    verticalGlyphOrientation: 'Right',
                    wordSpacing: 100,
                    billboardMode3D: 'FaceNearPlane',
                    offsetX: count > 9 ? 0 : -1,
                    offsetY: 2,
                  },
                  textString: count > 9 ? '9+' : `${count}`,
                },
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true,
              clippingPath: {
                type: 'CIMClippingPath',
                clippingType: 'Intersect',
                path: {
                  rings: [
                    [
                      [0, 0],
                      [40, 0],
                      [40, 40],
                      [0, 40],
                      [0, 0],
                    ],
                  ],
                },
              },
            },
          ],
        },
      },
    });
  }

  addImageMarkerStencil(url: string, rotation: number = 0, count = 1) {
    // @ts-ignore
    this.mapMarkerStencils.images = new CIMSymbol({
      data: {
        type: 'CIMSymbolReference',
        symbol: {
          type: 'CIMPointSymbol',
          symbolLayers: [
            {
              type: 'CIMVectorMarker',
              enable: true,
              anchorPointUnits: 'Relative',
              dominantSizeAxis3D: 'Z',
              size: 58,
              billboardMode3D: 'FaceNearPlane',
              rotation: parseInt(`${rotation}`, 10),
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 66,
                ymax: 78,
              },
              markerGraphics: [
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    rings: [
                      [
                        [64, 33.7],
                        [63.4, 27.5],
                        [61.6, 21.6],
                        [58.6, 16.1],
                        [54.6, 11.3],
                        [49.8, 7.3],
                        [44.2, 4.4],
                        [38.3, 2.6],
                        [32, 2],
                        [25.7, 2.6],
                        [19.8, 4.4],
                        [14.2, 7.3],
                        [9.4, 11.3],
                        [5.4, 16.1],
                        [2.4, 21.6],
                        [0.6, 27.5],
                        [0, 33.7],
                        [0.4, 38.9],
                        [1.7, 44],
                        [3.9, 48.8],
                        [6.8, 53.2],
                        [19.5, 66.7],
                        [27.9, 74.7],
                        [32, 78],
                        [36.1, 74.7],
                        [44.5, 66.7],
                        [57.2, 53.2],
                        [60.1, 48.8],
                        [62.3, 44],
                        [63.6, 38.9],
                        [64, 33.7],
                      ],
                    ],
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Butt',
                        joinStyle: 'Miter',
                        // @ts-ignore
                        lineStyle3D: 'Strip',
                        miterLimit: 4,
                        width: 1,
                        // @ts-ignore
                        height3D: 1,
                        anchor3D: 'Center',
                        color: [233, 236, 244, 255],
                      },
                    ],
                    angleAlignment: 'Map',
                  },
                },
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    rings: [
                      [
                        [32, 2],
                        [38.3, 2.6],
                        [44.2, 4.4],
                        [49.8, 7.3],
                        [54.6, 11.3],
                        [58.6, 16.1],
                        [61.6, 21.6],
                        [63.4, 27.5],
                        [64, 33.7],
                        [63.6, 38.9],
                        [62.3, 44],
                        [60.1, 48.8],
                        [57.2, 53.2],
                        [44.5, 66.7],
                        [36.1, 74.7],
                        [32, 78],
                        [27.9, 74.7],
                        [19.5, 66.7],
                        [6.8, 53.2],
                        [3.9, 48.8],
                        [1.7, 44],
                        [0.4, 38.9],
                        [0, 33.7],
                        [0.6, 27.5],
                        [2.4, 21.6],
                        [5.4, 16.1],
                        [9.4, 11.3],
                        [14.2, 7.3],
                        [19.8, 4.4],
                        [25.7, 2.6],
                        [32, 2],
                      ],
                    ],
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      // @ts-ignore
                      ...(count > 1 ? [
                        {
                          type: 'CIMVectorMarker',
                          enable: true,
                          anchorPoint: {
                            x: 0,
                            y: 0,
                          },
                          rotation: 360 - parseInt(`${rotation}`, 10),
                          offsetX: -25,
                          offsetY: -25,
                          anchorPointUnits: 'Relative',
                          dominantSizeAxis3D: 'Y',
                          size: 20,
                          billboardMode3D: 'FaceNearPlane',
                          frame: {
                            xmin: 0,
                            ymin: 0,
                            xmax: 17,
                            ymax: 17,
                          },
                          markerGraphics: [
                            {
                              type: 'CIMMarkerGraphic',
                              geometry: {
                                rings: [
                                  [
                                    [8.5, 0],
                                    [7.02, 0.13],
                                    [5.59, 0.51],
                                    [4.25, 1.14],
                                    [3.04, 1.99],
                                    [1.99, 3.04],
                                    [1.14, 4.25],
                                    [0.51, 5.59],
                                    [0.13, 7.02],
                                    [0, 8.5],
                                    [0.13, 9.98],
                                    [0.51, 11.41],
                                    [1.14, 12.75],
                                    [1.99, 13.96],
                                    [3.04, 15.01],
                                    [4.25, 15.86],
                                    [5.59, 16.49],
                                    [7.02, 16.87],
                                    [8.5, 17],
                                    [9.98, 16.87],
                                    [11.41, 16.49],
                                    [12.75, 15.86],
                                    [13.96, 15.01],
                                    [15.01, 13.96],
                                    [15.86, 12.75],
                                    [16.49, 11.41],
                                    [16.87, 9.98],
                                    [17, 8.5],
                                    [16.87, 7.02],
                                    [16.49, 5.59],
                                    [15.86, 4.25],
                                    [15.01, 3.04],
                                    [13.96, 1.99],
                                    [12.75, 1.14],
                                    [11.41, 0.51],
                                    [9.98, 0.13],
                                    [8.5, 0],
                                  ],
                                ],
                              },
                              symbol: {
                                type: 'CIMPolygonSymbol',
                                symbolLayers: [
                                  {
                                    type: 'CIMSolidStroke',
                                    enable: true,
                                    capStyle: 'Round',
                                    joinStyle: 'Round',
                                    // @ts-ignore
                                    lineStyle3D: 'Strip',
                                    miterLimit: 10,
                                    width: 0,
                                    color: [0, 0, 0, 255],
                                  },
                                  {
                                    type: 'CIMSolidFill',
                                    enable: true,
                                    color: [16, 54, 214, 255],
                                  },
                                ],
                              },
                            },
                            {
                              type: 'CIMMarkerGraphic',
                              geometry: {
                                x: 0,
                                y: 0,
                              },
                              symbol: {
                                type: 'CIMTextSymbol',
                                fontFamilyName: 'Arial',
                                rotation: 360 - parseInt(`${rotation}`, 10),
                                fontStyleName: 'Regular',
                                size: 8,
                                height: 8,
                                horizontalAlignment: 'Center',
                                offsetX: count > 9 ? 9 : 8,
                                offsetY: 9,
                                symbol: {
                                  type: 'CIMPolygonSymbol',
                                  symbolLayers: [
                                    {
                                      type: 'CIMSolidFill',
                                      enable: true,
                                      color: [255, 255, 255, 255],
                                    },
                                  ],
                                },
                                verticalAlignment: 'Center',
                              },
                              textString: count > 9 ? '9+' : String(count),
                            }
                          ],
                          scaleSymbolsProportionally: true,
                          respectFrame: true,
                        },
                      ] : []),
                      {
                        // @ts-ignore
                        type: 'CIMPictureMarker',
                        rotation: 360 - parseInt(`${rotation}`, 10),
                        enable: true,
                        // @ts-ignore
                        anchorPointUnits: 'Absolute',
                        // @ts-ignore
                        dominantSizeAxis3D: 'Z',
                        offsetX: 0,
                        offsetY: 0,
                        size: 40,
                        billboardMode3D: 'FaceNearPlane',
                        invertBackfaceTexture: true,
                        scaleX: 1,
                        textureFilter: 'Picture',
                        url: `${url}`,
                        tintColor: [255, 255, 255, 255],
                        anchorPoint: {
                          x: 0,
                          y: 0,
                        },
                        clippingPath: {
                          type: 'CIMClippingPat',
                          clippingType: 'Intersect',
                          path: {
                            rings: [
                              [
                                [8.5, 0],
                                [7.02, 0.13],
                                [5.59, 0.51],
                                [4.25, 1.14],
                                [3.04, 1.99],
                                [1.99, 3.04],
                                [1.14, 4.25],
                                [0.51, 5.59],
                                [0.13, 7.02],
                                [0, 8.5],
                                [0.13, 9.98],
                                [0.51, 11.41],
                                [1.14, 12.75],
                                [1.99, 13.96],
                                [3.04, 15.01],
                                [4.25, 15.86],
                                [5.59, 16.49],
                                [7.02, 16.87],
                                [8.5, 17],
                                [9.98, 16.87],
                                [11.41, 16.49],
                                [12.75, 15.86],
                                [13.96, 15.01],
                                [15.01, 13.96],
                                [15.86, 12.75],
                                [16.49, 11.41],
                                [16.87, 9.98],
                                [17, 8.5],
                                [16.87, 7.02],
                                [16.49, 5.59],
                                [15.86, 4.25],
                                [15.01, 3.04],
                                [13.96, 1.99],
                                [12.75, 1.14],
                                [11.41, 0.51],
                                [9.98, 0.13],
                                [8.5, 0],
                              ],
                            ],
                          },
                        },
                      },
                      {
                        // @ts-ignore
                        type: 'CIMSolidFill',
                        enable: true,
                        color: [255, 255, 255, 255],
                      },
                    ],
                    // @ts-ignore
                    angleAlignment: 'Map',
                  },
                },
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true,
              clippingPath: {
                type: 'CIMClippingPath',
                clippingType: 'Intersect',
                path: {
                  rings: [
                    [
                      [0, 0],
                      [66, 0],
                      [66, 78],
                      [0, 78],
                      [0, 0],
                    ],
                  ],
                },
              },
            },
          ],
        },
      },
    });
  }

  /**
   * Initialize the component when it is first created.
   */
  ngOnInit() {
    esriConfig.apiKey = ENV.ESRI_MAPS_API_KEY;
    this.initMap();
  }

  /**
   * Initialize the map with a basemap and set up the map view.
   */
  initMap() {
    // console.log(this.center, this.zoom);
    // Set the basemap from a portal item id
    const SNBasemap = new Basemap({
      baseLayers: [
        new VectorTileLayer({
          portalItem: {
            id: '9bc1cb8a67f4422c882bc55a4d4792ef',
          },
        }),
      ],
    });

    this.mapInstance = new Map({
      basemap: SNBasemap,
    });

    this.mapLoaded.emit(this.mapInstance);

    this.mapViewInstance = new MapView({
      container: this.mapViewNode.nativeElement,
      map: this.mapInstance,
      center: this.center,
      zoom: this.zoom,
      constraints: {
        snapToZoom: false,
      },
      ui: {
        components: []
      }
    });

    this.mapViewInstance.popupEnabled = false;

    this.mapProjectGroupLayer = new GroupLayer();
    this.mapSiteMarkersGroupLayer = new GroupLayer();
    this.mapSiteStationsGroupLayer = new GroupLayer();
    this.mapProjectGraphicsLayer = new GraphicsLayer();
    this.mapSiteMarkersGraphicsLayer = new GraphicsLayer();
    this.mapStationMarkersGraphicsLayer = new GraphicsLayer();

    // Add Graphics layers to respective groups
    this.mapProjectGroupLayer.add(this.mapProjectGraphicsLayer);
    this.mapSiteMarkersGroupLayer.add(this.mapSiteMarkersGraphicsLayer);
    this.mapSiteStationsGroupLayer.add(this.mapStationMarkersGraphicsLayer);
    // Finally add the GroupLayers to the map
    this.mapInstance.addMany([
      this.mapProjectGroupLayer,
      this.mapSiteMarkersGroupLayer,
      this.mapSiteStationsGroupLayer,
    ]);

    this.viewLoaded.emit(this.mapViewInstance);
    const mapLayerFilterCollection = new Collection();
    mapLayerFilterCollection.addMany([
      this.mapSiteMarkersGraphicsLayer,
      this.mapStationMarkersGraphicsLayer,
    ]);

    // Get screen point from view's click event
    this.mapViewInstance.on('click', event => {
      // Search for graphics on layers at the clicked location including graphicsLayer from the hitTest
      this.mapViewInstance.hitTest(event, { include: mapLayerFilterCollection }).then(response => {
        if (response.results?.length > 0) {
          const graphicHit = response.results[0];

          const graphic = graphicHit['graphic'];
          if (graphic.attributes?.type === 'marker' || graphic.attributes?.category === 'images') {
            // We have to pass ESRI layers to MapPopup by converting it to regular JavaScript JSON object
            // Read here for more: https://developers.arcgis.com/javascript/latest/api-reference/esri-core-Accessor.html
            this.markerClick.emit({
              // @ts-ignore
              screenPoint: event.screenPoint,
              mapPoint: event.mapPoint,
              graphic: graphic.toJSON(),
              // Add any other data you want to pass
            });
          }
        }
      });
    });
    // Fix for ESRI map cursor on markers
    this.mapViewInstance.on('pointer-move', (event) => {
      this.mapViewInstance.hitTest(event).then(response => {
        const element = response.results.find(graphicHit => {
          // @ts-ignore
          const graphic = graphicHit.graphic;

          return (graphic.attributes?.type === 'marker' || graphic.attributes?.category === 'images') && this.allowMarkerClick;
        });

        let temp;

        if (element && 'graphic' in element) {
          temp = element.graphic;
        }

        if (temp) {
          // @ts-ignore
          this.mapViewInstance.cursor = 'pointer';

          if (temp?.toJSON()?.attributes?.data?.level > 1) {
            if (temp.attributes?.category !== 'images') {
              this.markerHover.emit({
                screenPoint: {x: event.x, y: event.y},
                graphic: temp.toJSON(),
              });
            }
          }
        } else {
          this.markerHover.emit(null);
          // @ts-ignore
          this.mapViewInstance.cursor = 'default';
        }
      });
    });
    this.mapComponentLoaded.emit(this);
  }

  public toggleProjectLayers(type: string, show: boolean) {
    for (const graphic of this.mapProjectGraphicsLayer.graphics) {
      if (graphic?.attributes?.category === type) {
        graphic.visible = show;
      }
    }
  }

  public toggleSiteLayers(type: string, show: boolean) {
    for (const graphic of this.mapSiteMarkersGraphicsLayer.graphics) {
      if (graphic?.attributes?.category === type) {
        graphic.visible = show;
      }
    }
  }

  public updateCenterAndZoom(center: number[], zoom: number): void {
    this.center = center;
    this.zoom = zoom;

    // Update the map view's center and zoom here
    if (this.mapViewInstance) {
      // @ts-ignore
      this.mapViewInstance.center = center;
      this.mapViewInstance.zoom = zoom;
    }
  }

   drawPolygon(params: { geoJson: any[]; category: string; isLine: boolean; isDashed: boolean; }) {
    const {
      geoJson,
      category,
      isLine,
      isDashed
    } = params;

    const polygonGraphic = new Graphic({
      geometry: {
        // @ts-ignore
        type: isLine ? 'polyline' : 'polygon',
        ...(isLine ? { paths: geoJson } : {rings: geoJson}),
      },
      symbol: {
        // @ts-ignore
        type: isLine ? 'simple-line' : 'simple-fill',
        ...(isLine ? {
          color: [16, 54, 214, 1],
          width: 4
        } : {
          color: [189, 201, 255, 0.4],
          outline: {
            color: [16, 54, 214, 1],
            width: 2,
          },
        }),
        ...(isLine && isDashed ? { style: 'short-dash' } : {}),
      },
      attributes: {
        category,
      },
    });

    this.mapProjectGraphicsLayer.add(polygonGraphic);
  }

  drawProjectSiteMarkers(arrayMarkers = []) {
    if (arrayMarkers.length > 0) {
      for (const marker of arrayMarkers) {
        if (marker.symbol === 'ghost_locations') {
          // clonedStencils.ghost_locations.symbol.symbolLayers[0].markerGraphics[2].textString = marker?.count;
          this.addGhostLocationsStencil(marker?.count);
        } else if (marker.symbol === 'grouped_locations') {
          this.addGroupedLocationsStencil(marker?.data?.children?.length);
        }
        this.mapSiteMarkersGraphicsLayer.add(
          new Graphic({
            geometry: marker.geometry,
            symbol: this.mapMarkerStencils[marker.symbol],
            attributes: {
              type: 'marker',
              category: marker.symbol,
              data: marker.data
            },
          }),
        );
      }
    }
  }

  drawImageMarkers(arrayMarkers = []) {
    if (arrayMarkers.length > 0) {
      for (const marker of arrayMarkers) {
        if (marker.symbol === 'images') {
          this.addImageMarkerStencil(marker?.url, marker?.rotation, marker.data.count);
        }
        this.mapStationMarkersGraphicsLayer.add(
          new Graphic({
            geometry: marker.geometry,
            symbol: this.mapMarkerStencils[marker.symbol],
            attributes: {
              data: marker.data,
              type: 'marker',
              category: marker.symbol,
            },
          }),
        );
      }
    }
  }

  /**
   * Retrieves the map instance.
   *
   * @return __esri.Map the map instance
   */
  getMapInstance() {
    return this.mapInstance;
  }

  /**
   * Returns the map view instance.
   *
   * @return type - description of return value
   */
  getMapViewInstance() {
    return this.mapViewInstance;
  }
}
