<div class="center">
  <div class="stroked-card">
    <ng-container *ngIf="!email && !phone && !magicCode">
      <ng-container *ngTemplateOutlet="CheckUserContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="email && !phone && !magicCode">
      <ng-container *ngTemplateOutlet="EmailLoginContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="phone && !email && !magicCode">
      <ng-container *ngTemplateOutlet="PhoneLoginContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="magicCode">
      <ng-container *ngTemplateOutlet="MagicLoginLoading"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #CheckUserContent>
  <section class="header">
    <div class="mat-headline">
      <a href="/" class="logo"></a>
    </div>
    <div class="mat-subheading-1">Sign in</div>
  </section>

  <form [formGroup]="checkUserForm">
    <section class="body">

      <!-- Username -->
      <section>
        <mat-form-field>
          <mat-label>Email or phone</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="username" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="checkUserForm.controls.username.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="checkUserForm.controls.username.hasError('error')">
            {{checkUserForm.controls.username.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /Username -->

      <!-- Form Actions -->
      <section>
        <div class="row">
          <div></div>
          <div class="icons">
            <button mat-stroked-button color="primary" (click)="checkUser()">Next</button>
          </div>
        </div>
      </section>
      <!-- Form Actions -->


    </section>
  </form>

  <section class="footer"></section>

</ng-template>


<!-- Login via email options -->
<ng-template #EmailLoginContent>


  <section class="header">
    <div class="mat-headline"><a href="/" class="logo"></a></div>
    <div class="mat-subheading-1">Welcome back {{email}}</div>
  </section>

  <section class="body">

    <div *ngIf="!magicLinkMsg">
      <form [formGroup]="loginForm" class="v-gap-10">
        <!-- Password -->
        <section>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput type="password" autocomplete="off" formControlName="password" [errorStateMatcher]="matcher"
              id="password" />
            <mat-error *ngIf="loginForm.controls.password.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="loginForm.controls.password.hasError('error')">
              {{loginForm.controls.password.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Username -->

        <!-- Form Actions -->
        <section>
          <div class="row">
            <div>
              <div class="primary"><a class="mat-subheading-2 no-style" href="#">Forgot Password?</a></div>
              <div class="accent"><a class="mat-subheading-2 no-style" (click)="resetLogin()">Not {{email}}?</a></div>
            </div>
            <div class="icons">
              <!-- <button mat-stroked-button color="accent" (click)="resetLogin()">Back</button> -->

              <button mat-stroked-button color="primary" (click)="loginWithPassword()">Sign in</button>
            </div>
          </div>
        </section>
        <!-- Form Actions -->

      </form>
    </div>

    <div class="divider" *ngIf="!magicLinkMsg">
      <span>OR</span>
    </div>

    <div class="center v-gap-10">
      <ng-container *ngIf="magicLinkMsg">
        <div class="mat-subheading-2">Check your email!</div>
        <p>We've sent a sign-in link to <b>{{email}}</b>.</p>
        <p>Just click the link to sign in.</p>
      </ng-container>
      <ng-container *ngIf="!magicLinkMsg">
        <button mat-flat-button color="primary" (click)="getMagicLink()">Email me a link</button>
        <div class="mat-caption">We'll email you a link. Click on it to sign in.</div>
      </ng-container>
    </div>
  </section>

  <section class="footer"></section>

</ng-template>
<!-- Login via email options -->


<!-- MagicLoginLoading -->
<ng-template #MagicLoginLoading>
  <section class="header">
    <div class="mat-headline"><a href="/" class="logo"></a></div>
    <div class="mat-subheading-1">Signing you in with email link...</div>
  </section>

  <section class="body">
    <div class="mat-subheading-2 center" *ngIf="!magicError">Please wait while we validate your sign-in link.</div>
    <div class="mat-subheading-2 error" *ngIf="magicError">{{magicError}}</div>
    <div class="row">
      <div></div>
      <div class="icons">
        <button mat-stroked-button color="accent" (click)="resetLogin()">Back</button>
      </div>
    </div>
  </section>
</ng-template>
<!-- MagicLoginLoading -->


<!-- Login via Phone -->
<ng-template #PhoneLoginContent>
  <section class="header">
    <div class="mat-headline"><a href="/" class="logo"></a></div>
    <div class="mat-subheading-1">Welcome back {{phone}}</div>
  </section>

  <section class="body">
    <div class="mat-subheading-2">Sent an OTP to {{phone}}</div>
    <div class="">
      <form [formGroup]="phoneForm" class="v-gap-10">
        <!-- OTP -->
        <section>
          <mat-form-field>
            <mat-label>Enter OTP</mat-label>
            <input matInput type="number" autocomplete="off" formControlName="otp" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="phoneForm.controls.otp.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="phoneForm.controls.otp.hasError('error')">
              {{phoneForm.controls.otp.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Username -->

        <!-- Form Actions -->
        <section>
          <div class="row">
            <div></div>
            <div class="icons">
              <button mat-stroked-button color="accent" (click)="resetLogin()">Back</button>
              <button mat-stroked-button color="primary" (click)="loginWithPhone()">Next</button>
            </div>

          </div>
        </section>
        <!-- Form Actions -->

      </form>
    </div>

  </section>
</ng-template>
<!-- Login via Phone -->
