<div class="wrapper">
  <div class="heading text-type-2">{{ data?.bidItem?.item }}</div>
  <div class="description text-type-10">{{ data?.bidItem?.description }}</div>

  <app-station-wise-bid-item-details
    *ngIf="data?.stations !== undefined"
    [bidItem]="data?.bidItem"
    [heading]="data?.heading"
    [stationsList]="data?.stations"
  />
  <app-multiline-bid-item-details
    *ngIf="data?.lineItems !== undefined"
    [bidItem]="data.bidItem"
    [lineItems]="data.lineItems"
  />
  <app-actions-section
    [disableAddMenu]="disableAddMenu"
    class="actions-section w-[455px]"
  />
</div>
