/* NgRx */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './shared.reducer';

// Selector functions
const sharedData = createFeatureSelector<SharedState>('sitenotes.shared');

export const isLoading = createSelector(sharedData, state => state.loading);

export const workConditions = createSelector(sharedData, state => state.workConditions);

export const weatherConditions = createSelector(sharedData, state => state.weatherConditions);
