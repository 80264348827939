import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PortalModule } from './layouts/portal/portal.module';
import { DefaultModule } from './layouts/default/default.module';
import { AbilityModule } from '@casl/angular';
import { Ability, createAliasResolver, PureAbility } from '@casl/ability';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { SharedModule } from './shared/shared.module';
import { ProjectsModule } from './modules/projects/projects.module';

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['sitenotes.shared', 'sitenotes.projects'],
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

const resolveAction = createAliasResolver({
  crud: ['create', 'read', 'update', 'delete'],
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PortalModule,
    DefaultModule,
    AbilityModule,
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    ProjectsModule,
  ],
  providers: [
    { provide: Ability, useValue: new Ability([], { resolveAction }) },
    { provide: PureAbility, useExisting: Ability },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
