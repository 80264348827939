import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import {takeUntil} from 'rxjs/operators';
import {dailyReport, siteDetails} from '../../../../../../../../core/projects.selectors';
import {DailyReportService} from '../../../../daily-report.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-station-wise-bid-item-details',
  templateUrl: './station-wise-bid-item-details.component.html',
  styleUrls: ['./station-wise-bid-item-details.component.scss']
})
export class StationWiseBidItemDetailsComponent implements OnInit, OnChanges, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() stationsList: any[] = [];
  @Input() bidItem: any;
  @Input() heading: any;

  headerStation: any;

  quantityTotal: number = null;

  stations: any[];

  headings: any = [];
  stationOptions: any[];


  form: FormGroup = new FormGroup({});
  configurationForm: FormGroup = new FormGroup({
    heading: new FormControl(''),
    from: new FormControl(''),
    to: new FormControl(''),
  });

  isFormReady = false;

  selectedStationId = '';
  selectedStationPictures = [];

  formSubscription: Subscription = new Subscription();

  headingOptions = [];

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dailyReportService: DailyReportService,
  ) {
  }

  ngOnInit() {
    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(site => {
        this.headings = site.headings;
        this.getStationsListForSelectionFilter();
        this.headingOptions = site.headings.map(o => ({
          label: o.name,
          value: o.id
        }));
      });

    this.configurationForm.controls.heading.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(headingId => {
        this.getStationsListForSelectionFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formSubscription?.unsubscribe();

    this.stations = JSON.parse(JSON.stringify(this.stationsList));

    this.dailyReportService.disableAddMenu.next(false);

    const index = this.stations.findIndex((o) => o.station === null);

    if (index > -1) {
      this.headerStation = this.stations.splice(index, 1)[0];
    }

    this.stations.sort((a, b) => {
      let first = a.station?.name?.replace(/\+/g, '');
      first = Number(first);

      let second = b.station?.name?.replace(/\+/g, '');
      second = Number(second);

      if (this.heading.direction === 'desc') {
        return second - first;
      }

      return first - second;
    });

    this.configurationForm.patchValue({
      heading: this.heading.id,
    }, {emitEvent: false});

    this.getStationsListForSelectionFilter();

    this.isFormReady = false;

    this.form = this.createStationForm([...this.stations, this.headerStation]);

    this.formSubscription = this.form.valueChanges
      .subscribe((v) => {
        // TODO :: Calculate the quantity
      });


    this.isFormReady = true;
  }

  selectStation(stationId) {
    this.selectedStationId = this.selectedStationId === stationId ? '' : stationId;
    this.selectedStationPictures = this.getSelectedStationPictures();
  }

  getSelectedStationPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedStationId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  getFormGroup(form, controlName) {
    return form.get(controlName) as FormGroup;
  }

  createStationForm(stations) {
    const parentFormBuilder = {};
    stations?.map(station => {
      if (station) {
        parentFormBuilder[station.id] = this.fb.group({});

        station.field_values.map(field => {
          parentFormBuilder[station.id].addControl(field.id, new FormControl(field.value));
        });

        if (station.comment) {
          parentFormBuilder[station.id].addControl('comment', new FormControl(station.comment));
        }

        parentFormBuilder[station.id].addControl('pictures', new FormControl(JSON.stringify(station.pictures)));
      }
    });

    return this.fb.group(parentFormBuilder);
  }

  getStationsListForSelectionFilter() {
    const headingId = this.configurationForm.controls.heading.value;

    if (headingId && this.headings) {
      this.stationOptions = (this.headings.find(o => o.id === headingId))?.stations.map(o => ({
        label: o.name,
        value: o.id
      }));
    }
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
