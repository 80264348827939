import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import {DailyReportService} from '../../../../daily-report.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-bid-item-details-wrapper',
  templateUrl: './bid-item-details-wrapper.component.html',
  styleUrls: ['./bid-item-details-wrapper.component.scss']
})
export class BidItemDetailsWrapperComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() data: any;

  disableAddMenu = false;


  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private dailyReportService: DailyReportService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.dailyReportService.disableAddMenu
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        this.disableAddMenu = d;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
