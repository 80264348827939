import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import * as fromRoot from '../../../state/app.state';
import {
  AddIssuesDetailsFailure,
  AddIssuesDetailsRequest,
  AddIssuesDetailsSuccess,
  ChangeIssueStatusFailure,
  ChangeIssueStatusRequest,
  ChangeIssueStatusSuccess,
  AddContractorStatusFailure,
  AddContractorStatusRequest,
  AddContractorStatusSuccess,
  CheckIfDailyReportCanBeCreatedFailure,
  CheckIfDailyReportCanBeCreatedRequest,
  CheckIfDailyReportCanBeCreatedSuccess,
  CreateDailyReportFailure,
  CreateDailyReportRequest,
  CreateDailyReportSuccess,
  DeletePayappFailure,
  DeletePayappRequest,
  DeletePayappSuccess,
  ExecuteCurrentActionStateFailure,
  ExecuteCurrentActionStateRequest,
  ExecuteCurrentActionStateSuccess,
  FetchAllBidItemsFailure,
  FetchAllBidItemsRequest,
  FetchAllBidItemsSuccess,
  FetchBidItemListWithChangeOrderFailure,
  FetchBidItemListWithChangeOrderInSideNavFailure,
  FetchBidItemListWithChangeOrderInSideNavRequest,
  FetchBidItemListWithChangeOrderInSideNavSuccess,
  FetchBidItemListWithChangeOrderRequest,
  FetchBidItemListWithChangeOrderSuccess,
  FetchBidItemsListFailure,
  FetchBidItemsListRequest,
  FetchBidItemsListSuccess,
  FetchBidItemsSitesListFailure,
  FetchBidItemsSitesListRequest,
  FetchBidItemsSitesListSuccess,
  FetchBidSheetFailure,
  FetchBidSheetRequest,
  FetchBidSheetSuccess,
  FetchChangeOrderFilterFailure,
  FetchChangeOrderFilterInSideNavFailure,
  FetchChangeOrderFilterInSideNavRequest,
  FetchChangeOrderFilterInSideNavSuccess,
  FetchChangeOrderFilterRequest,
  FetchChangeOrderFilterSuccess,
  FetchDailyReportFailure,
  FetchDailyReportRequest,
  FetchDailyReportsByDateFailure,
  FetchDailyReportsByDateRequest,
  FetchDailyReportsByDateSuccess,
  FetchDailyReportSuccess,
  FetchEquipmentFilterFailure,
  FetchEquipmentFilterRequest,
  FetchEquipmentFilterSuccess,
  FetchIssueTypesFailure,
  FetchIssueTypesRequest,
  FetchIssueTypesSuccess,
  FetchLaborFilterFailure,
  FetchLaborFilterRequest,
  FetchLaborFilterSuccess,
  FetchPayappDetailsFailure,
  FetchPayappDetailsRequest,
  FetchPayappDetailsSuccess,
  FetchPayappItemsFailure,
  FetchPayappItemsRequest,
  FetchPayappItemsSuccess,
  FetchPayappsFailure,
  FetchPayappsRequest,
  FetchPayappsSuccess,
  FetchProjectDetailsFailure,
  FetchProjectDetailsRequest,
  FetchProjectDetailsSuccess,
  FetchProjectMembersFailure,
  FetchProjectMembersRequest,
  FetchProjectMembersSuccess,
  FetchProjectReportFailure,
  FetchProjectReportRequest,
  FetchProjectReportsListFailure,
  FetchProjectReportsListRequest,
  FetchProjectReportsListSuccess,
  FetchProjectReportSuccess,
  FetchProjectsListFailure,
  FetchProjectsListRequest,
  FetchProjectsListSuccess,
  FetchSiteDetailsFailure,
  FetchSiteDetailsRequest,
  FetchSiteDetailsSuccess,
  FetchSiteWiseDailyReportSummaryFailure,
  FetchSiteWiseDailyReportSummaryRequest,
  FetchSiteWiseDailyReportSummarySuccess,
  FetchSubContractorFilterFailure,
  FetchSubContractorFilterRequest,
  FetchSubContractorFilterSuccess,
  GeneratePayappFailure,
  GeneratePayappRequest,
  GeneratePayappSuccess,
  SaveDailyReportFailure,
  SaveDailyReportRequest,
  SaveDailyReportSuccess,
  UpdateIssuesDetailsFailure,
  UpdateIssuesDetailsRequest,
  UpdateIssuesDetailsSuccess,
  UpdatePayappStatusFailure,
  UpdatePayappStatusRequest,
  UpdatePayappStatusSuccess,
  UploadChangeOrderFileFailure,
  UploadChangeOrderFileRequest,
  UploadChangeOrderFileSuccess,
} from './projects.actions';
import { ProjectsService } from './projects.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services';
import { DailyReportService } from '../pages/site-details-page/tab-screens/daily-report/daily-report.service';
import { DatePipe } from '@angular/common';
import { projectDetails } from './projects.selectors';
import { IssueService } from '../../issue/issue.service';

@Injectable()
export class ProjectsEffects {
  fetchProjectsList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectsListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectsList(payload).pipe(
          map(response => {
            const { data } = response;

            const projectsFilterList = [];
            const sitesFilterData = {};

            data.map(d => {
              d['durationProgress'] = (100 * d.duration_used) / d.duration;
              d['valueProgress'] = (100 * d.earned_value) / d.project_value;

              projectsFilterList.push({ value: d.id, label: d.name });

              const sitesFilterList = [];
              d.sites?.map(s => {
                sitesFilterList.push({ value: s.id, label: s.name });
              });

              sitesFilterData[d.id] = sitesFilterList;
            });

            return FetchProjectsListSuccess({
              payload: {
                projectsList: data,
                projectsFilterList,
                sitesFilterData,
              },
            });
          }),
          catchError(() => {
            return of(FetchProjectsListFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchProjectsListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectsListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectDetails(payload).pipe(
          map(response => {
            const { data } = response;
            data['durationProgress'] = (100 * data.duration_used) / data.duration;
            data['valueProgress'] = (100 * data.earned_value) / data.project_value;

            return FetchProjectDetailsSuccess({
              projectDetails: data,
            });
          }),
          catchError(() => {
            return of(FetchProjectDetailsFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchProjectDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchSiteWiseDailyReportsSummary: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSiteWiseDailyReportSummaryRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchSiteWiseDailyReportSummary(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchSiteWiseDailyReportSummarySuccess({
              siteWiseDailyReportsSummary: data,
            });
          }),
          catchError(() => {
            return of(FetchSiteWiseDailyReportSummaryFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchSiteWiseDailyReportSummarySuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSiteWiseDailyReportSummaryFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectMembers$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectMembersRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectMembers(payload).pipe(
          map(response => {
            const { data } = response;

            const currentUser = this.authService.getCurrentUser();

            const projectMembersFilterList = data.map(o => {
              const name = `${o.first_name} ${o.last_name}`;

              return {
                value: o.id,
                label: o.id === currentUser.id ? `Me (${name})` : name,
              };
            });

            const index = projectMembersFilterList.findIndex(o => o.label.includes('Me ('));

            if (index > -1) {
              const element = projectMembersFilterList.splice(index, 1);
              projectMembersFilterList.unshift(...element);
            }

            return FetchProjectMembersSuccess({ projectMembersFilterList });
          }),
          catchError(() => {
            return of(FetchProjectMembersFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchProjectMembersSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectMembersFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  createDailyReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateDailyReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.createDailyReport(payload).pipe(
          map(response => {
            return CreateDailyReportSuccess({ data: response.data });
          }),
          catchError(() => {
            return of(CreateDailyReportFailure());
          }),
          tap((action: any) => {
            if (action.type === CreateDailyReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeCreateDailyReportDialog.next();
              this.commonService.notification('Daily report created successfully', 'success');
              const { data } = action;
              const url = `/projects/${data.project.id}/sites/${data.site.id}`;
              const qp = {
                tab: 'daily-report',
                reportId: data.id,
                date: data.report_date,
              };
              const pageUrl = this.router.url.split('?')[0];
              if (url === pageUrl) {
                this.router.navigate(['/'])
                  .then(() => {
                    this.router.navigate([url], {
                      queryParams: qp,
                    });
                  });
              } else {
                this.router.navigate([url], {
                  queryParams: qp,
                });
              }
            } else if (action.type === CreateDailyReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  checkIfDailyReportCanBeCreated$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(CheckIfDailyReportCanBeCreatedRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchDailyReports(payload).pipe(
          map(response => {
            const { data } = response;

            const temp = data.filter(o => o.createdBy.id === payload.createdBy && o.site.id === payload.site);

            return CheckIfDailyReportCanBeCreatedSuccess({ existingReportId: temp[0]?.id });
          }),
          catchError(() => {
            return of(CheckIfDailyReportCanBeCreatedFailure());
          }),
          tap((action: any) => {
            if (action.type === CheckIfDailyReportCanBeCreatedSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === CheckIfDailyReportCanBeCreatedFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectReports$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectReportsListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectReports(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchProjectReportsListSuccess({ projectReportsList: data });
          }),
          catchError(() => {
            return of(FetchProjectReportsListFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchProjectReportsListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectReportsListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectReports(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchProjectReportSuccess({ projectReport: data[0] });
          }),
          catchError(() => {
            return of(FetchProjectReportFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchProjectReportSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchPayappItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPayappItemsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchPayappItems(payload).pipe(
          map(response => FetchPayappItemsSuccess({ payappItems: response.data })),
          catchError(() => of(FetchPayappItemsFailure())),
          tap(action => {
            this.commonService.stopLoading();

            if (action.type === FetchPayappItemsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchPayappItemsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  fetchPayapps$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPayappsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchPayapps(payload).pipe(
          map(response => FetchPayappsSuccess({ payapps: response.data })),
          catchError(() => of(FetchPayappsFailure())),
          tap(action => {
            this.commonService.stopLoading();

            if (action.type === FetchPayappsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchPayappsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  fetchPayappdetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPayappDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchPayappDetails(payload).pipe(
          map(response => {
            const { bid_items, id, name } = response.data;
            return FetchPayappDetailsSuccess({
              payappItems: bid_items,
              payappId: id,
              payappName: name,
            });
          }),
          catchError(() => of(FetchPayappDetailsFailure())),
          tap(action => {
            this.commonService.stopLoading();

            if (action.type === FetchPayappDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchPayappDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  generatePayapp$ = createEffect(() =>
    this.actions.pipe(
      ofType(GeneratePayappRequest),
      mergeMap(action => {
        this.commonService.startLoading();
        return this.projectsService.generatePayapp(action.payload).pipe(
          map(response => GeneratePayappSuccess({ data: response.data })),
          catchError(() => of(GeneratePayappFailure())),
          tap((action: any) => {
            this.commonService.stopLoading();
            if (action.type === GeneratePayappSuccess.type) {
              this.commonService.notification('Payapp generated successfully', 'success');
            } else if (action.type === GeneratePayappFailure.type) {
              console.error('Failed to generate pay app');
            }
          }),
          withLatestFrom(this.store.select(projectDetails)),  // Combine with latest project details from store
          concatMap(([action, projectDetails]) => {
            if (action.type === GeneratePayappSuccess.type) {
              const projectId = projectDetails.id; // Retrieve projectId from store
              const generatedPayappId = action.data.id;

              // Step 1: Dispatch FetchPayappsRequest
              this.store.dispatch(FetchPayappsRequest({
                payload: {
                  projectId, // Use projectId from store
                },
              }));

              // Return an object that keeps projectId and generatedPayappId in scope
              return of({ projectId, generatedPayappId });
            } else {
              // If the action is not GeneratePayappSuccess, return an empty observable
              return EMPTY;
            }
          }),
          concatMap(({ projectId, generatedPayappId }) => {
            const qp = {
              includeDeferred: true,
              includeRejected: true,
            };

            this.store.dispatch(FetchPayappItemsRequest({
              payload: {
                projectId,
                qp,
              },
            }));
            return of({ projectId, generatedPayappId });

          }),
          concatMap(({ projectId, generatedPayappId }) => {
            // Step 2: Once prior requests completes, dispatch FetchPayappDetailsRequest
            return of(FetchPayappDetailsRequest({
              payload: {
                projectId,
                payappId: generatedPayappId,
                qp: {
                  include: ['bid_items'],
                },
              },
            }));
          }),
        );
      }),
    ),
  );

  siteDetailsReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSiteDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchSiteDetails(payload).pipe(
          map(response => {
            const { data } = response;

            const siteDetails = {
              id: data?.id,
              name: data?.name,
              project: {
                id: data?.project?.id,
                name: data?.project?.name,
                owner: data?.project?.owner_organization?.name,
              },
              latitude: data?.latitude,
              longitude: data?.longitude,
              imageUrl: data?.project?.cover_photo?.thumb_url,
              headings: data?.headings || [],
            };

            return FetchSiteDetailsSuccess({ siteDetails });
          }),
          catchError(() => {
            return of(FetchSiteDetailsFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchSiteDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSiteDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchDailyReportsByDate$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchDailyReportsByDateRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchDailyReports(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchDailyReportsByDateSuccess({ dailyReportsByDate: data });
          }),
          catchError(() => {
            return of(FetchDailyReportsByDateFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchDailyReportsByDateSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchDailyReportsByDateFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchDailyReportById$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchDailyReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchDailyReport(payload).pipe(
          map(response => {
            const { data } = response;
            const {
              activities,
              weather,
              labor,
              equipment,
            } = data;

            let { issues } = data;

            // Restructure bid items so that they are grouped by bid item id and heading and
            // can be used further as the basis of creating the UI

            const bidItems: any = {};

            for (const currentBidItem of data.bid_items) {
              if (bidItems[`${currentBidItem.bid_item.id}_${currentBidItem.heading_id}`] === undefined) {
                bidItems[`${currentBidItem.bid_item.id}_${currentBidItem.heading_id}`] = [currentBidItem];
              } else {
                bidItems[`${currentBidItem.bid_item.id}_${currentBidItem.heading_id}`].push(currentBidItem);
              }
            }

            const bidItemsArr = [];

            for (const key in bidItems) {
              if (bidItems.hasOwnProperty(key)) {
                bidItemsArr.push({
                  id: bidItems?.[key]?.[0]?.bid_item?.id,
                  bidItem: bidItems?.[key]?.[0]?.bid_item,
                  heading: bidItems?.[key]?.[0]?.heading,
                  ...(bidItems?.[key]?.[0]?.bid_item?.record_by_station ?
                    { stations: bidItems?.[key] } :
                    { lineItems: bidItems?.[key] }),
                });
              }
            }

            // Restructure material_deliveries into delivery and usage sections
            const formattedMaterialDelivery = this.dailyReportService.formatDeliveriesAndUsages(data.material_deliveries);

            let issuesScopeOptions = [
              {
                ...data.project,
                type: 'project',
              },
              {
                ...data.site,
                type: 'site',
              },
            ];
            issuesScopeOptions = issuesScopeOptions.map(scope => {
              return {
                label: scope.name,
                value: scope.id,
                type: scope.type,
              };
            });

            issues = issues.map(eachIssue => {
              return {
                ...eachIssue,
                issuesScopeOptions,
                projectId: data?.project?.id,
              };
            });

            return FetchDailyReportSuccess({
              dailyReport: {
                ...data,
                reportDetails: {
                  bidItems: bidItemsArr,
                  activities,
                  weather,
                  deliveries: formattedMaterialDelivery.deliveries,
                  usages: formattedMaterialDelivery.usages,
                  issues,
                  labors: labor,
                  equipments: equipment,
                },
              },
            });
          }),
          catchError((e) => {
            return of(FetchDailyReportFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchDailyReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next();
            } else if (action.type === FetchDailyReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  saveDailyReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SaveDailyReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.saveDailyReport(payload).pipe(
          map(() => {
            return SaveDailyReportSuccess({});
          }),
          catchError(() => {
            return of(SaveDailyReportFailure());
          }),
          tap((action: any) => {
            if (action.type === SaveDailyReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.dailyReportService.fetchDailyReportById.next();
              this.projectsService.isAnyFormDirty.next(false);
              this.commonService.notification('Report saved successfully.', 'success');
            } else if (action.type === SaveDailyReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchAllBidItems$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchAllBidItemsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectDetails(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.bid_items.map((bidItem: any) => {
              return {
                ...bidItem,
                label: bidItem.description,
                value: bidItem.id,
              };
            });
            return FetchAllBidItemsSuccess({
              allBidItems: data.bid_items,
              allBidItemsFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchAllBidItemsFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchAllBidItemsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchAllBidItemsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidSheet$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidSheetRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidSheet(payload).pipe(
          map(response => {
            const { data } = response;
            return FetchBidSheetSuccess({
              bidSheet: data,
            });
          }),
          catchError(() => {
            return of(FetchBidSheetFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidSheetSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidSheetFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItems$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemsListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItems(payload).pipe(
          map(response => {
            const { data, meta } = response;
            return FetchBidItemsListSuccess({
              bidItemsListInfo: { list: data, totalRecords: meta.totalRecords },
            });
          }),
          catchError(() => {
            return of(FetchBidItemsListFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemsListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemsListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  uploadChangeOrderFile$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UploadChangeOrderFileRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.uploadChangeOrderFile(payload).pipe(
          map(response => {
            const { data } = response;
            return UploadChangeOrderFileSuccess({
              changeOrderList: data,
            });
          }),
          catchError((error) => {
            let displayError: any;
            if (error.error.status === 422) {
              displayError = Object.entries(error?.error?.error).map(
                ([key, value]) => {
                  const rowNumber = +value['param']?.split('.')[0] + 1;
                  const columnName = value['param']?.split('.')[1];
                  return {
                    rowNumber,
                    columnName,
                    msg: value['msg'],
                  };
                },
              );
            }
            if (error.error.status === 500) {
              displayError = 'An Unknown error occurred, please check you file type';
            }
            this.projectsService.error.next(displayError);
            return of(UploadChangeOrderFileFailure({}));
          }),
          tap((action: any) => {
            if (action.type === UploadChangeOrderFileSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog.next();
              this.commonService.openSuccessDialog.next();
            } else if (action.type === UploadChangeOrderFileFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItemsSitesList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemsSitesListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItemsSitesList(payload).pipe(
          map(response => {
            const { data } = response;
            const { site_quantities, ...rest } = data;
            return FetchBidItemsSitesListSuccess({
              bidItemsSiteListInfo: {
                list: site_quantities,
                bidItemInfo: rest,
              },
            });
          }),
          catchError(() => {
            return of(FetchBidItemsSitesListFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemsSitesListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemsSitesListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItemListWithChangeOrder$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemListWithChangeOrderRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItemListWithChangeOrder(payload).pipe(
          map(response => {
            const { data } = response;
            const { bid_items } = data;
            const totalRecords = bid_items?.length;
            return FetchBidItemListWithChangeOrderSuccess({
              bidItemsListInfo: { list: bid_items, totalRecords },
            });
          }),
          catchError(() => {
            return of(FetchBidItemListWithChangeOrderFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemListWithChangeOrderSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemListWithChangeOrderFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItemListWithChangeOrderInSideNav$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemListWithChangeOrderInSideNavRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItemListWithChangeOrderInSideNav(payload).pipe(
          map(response => {
            const { data } = response;
            const { bid_items } = data;
            const { sites, ...rest } = (bid_items.filter(o => o.bid_item_id === payload.bidItemId))[0];
            const sitesList = bid_items?.filter(bi => bi.bid_item_id === payload?.bidItemId)[0]?.sites;
            return FetchBidItemListWithChangeOrderInSideNavSuccess({
              bidItemsSiteListInfo: {
                list: sitesList,
                bidItemInfo: rest,
              },
            });
          }),
          catchError(() => {
            return of(FetchBidItemListWithChangeOrderInSideNavFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemListWithChangeOrderInSideNavSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemListWithChangeOrderInSideNavFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  deletePayapp$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeletePayappRequest),
      mergeMap(action => {
        this.commonService.startLoading();
        return this.projectsService.deletePayapp(action.payload.projectId, action.payload.payappId).pipe(
          map(response => DeletePayappSuccess({ data: response })),
          catchError(() => of(DeletePayappFailure())),
          tap((action: any) => {
            this.commonService.stopLoading();
            if (action.type === DeletePayappFailure.type) {
              this.commonService.notification('Failed to recall pay app', 'error');
            }
          }),
          withLatestFrom(this.store.select(projectDetails)),
          concatMap(([action, projectDetails]) => {
            if (action.type === DeletePayappSuccess.type) {
              const projectId = projectDetails?.id;

              // Step 1: Dispatch FetchPayappsRequest
              this.store.dispatch(FetchPayappsRequest({
                payload: {
                  projectId,
                },
              }));
              return of({ projectId });
            } else {
              return EMPTY;
            }
          }),
          concatMap(({ projectId }) => {
            const qp = {
              includeDeferred: true,
              includeRejected: true,
            };
            this.store.dispatch(FetchPayappItemsRequest({
              payload: {
                projectId,
                qp,
              },
            }));
            return of({ projectId });
          }),
          concatMap(({ projectId }) => {
            this.commonService.notification('Payapp recalled successfully', 'success');
            return of(DeletePayappSuccess({ data: projectId }));
          }),
        );

      }),
    ),
  );

  updatePayappStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdatePayappStatusRequest),
      mergeMap(action => {
        return this.projectsService.updatePayappStatus(action.payload).pipe(
          map(response => UpdatePayappStatusSuccess({ data: response.data })),
          catchError(() => of(UpdatePayappStatusFailure())),
        );
      }),
    ),
  );
  
  fetchIssueTypes$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchIssueTypesRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchIssueTypes(payload).pipe(
          map(response => {
            const { data } = response;

            const issueTypesLabelMap = {};

            data.forEach((item) => {
              issueTypesLabelMap[item.type] = item.label;
            });

            return FetchIssueTypesSuccess({
              issueTypesLabelMap,
              issueTypesFilter: data.map(o => ({ value: o.id, label: o.label, ...o })),
            });
          }),
          catchError(() => {
            return of(FetchIssueTypesFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchIssueTypesSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchIssueTypesFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchSubContractorFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSubContractorFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.projectsService.fetchSubContractorFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              };
            });
            return FetchSubContractorFilterSuccess({
              subContractorFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchSubContractorFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchSubContractorFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSubContractorFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchLaborFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchLaborFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.projectsService.fetchLaborFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
                value: item.value,
                label: item.value,
              };
            });
            return FetchLaborFilterSuccess({
              laborFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchLaborFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchLaborFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchLaborFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchEquipmentFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchEquipmentFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.projectsService.fetchEquipmentFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
                value: item.value,
                label: item.value,
              };
            });
            return FetchEquipmentFilterSuccess({
              equipmentFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchEquipmentFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchEquipmentFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchEquipmentFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  updateIssueDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateIssuesDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.updateIssuesDetails(payload).pipe(
          map(response => {
            const { data } = response;
            return UpdateIssuesDetailsSuccess({});
          }),
          catchError(() => {
            return of(UpdateIssuesDetailsFailure());
          }),
          tap((action: any) => {
            if (action.type === UpdateIssuesDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next(true);
              this.projectsService.isAnyFormDirty.next(false);
              this.issueService.fetchIssuesList.next(true);
              this.dailyReportService.fetchDailyReportById.next(true);
            } else if (action.type === UpdateIssuesDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  addIssueDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddIssuesDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.addIssuesDetails(payload).pipe(
          map(() => {
            return AddIssuesDetailsSuccess({});
          }),
          catchError(() => {
            return of(AddIssuesDetailsFailure());
          }),
          tap((action: any) => {
            if (action.type === AddIssuesDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next(true);
              this.projectsService.isAnyFormDirty.next(false);
              this.issueService.fetchIssuesList.next(true);
              this.dailyReportService.fetchDailyReportById.next(true);
            } else if (action.type === AddIssuesDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  changeIssueStatus$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeIssueStatusRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.changeIssueStatus(payload).pipe(
          map(() => {
            return ChangeIssueStatusSuccess({});
          }),
          catchError(() => {
            return of(ChangeIssueStatusFailure());
          }),
          tap((action: any) => {
            if (action.type === ChangeIssueStatusSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next(true);
              this.projectsService.isAnyFormDirty.next(false);
              this.issueService.fetchIssuesList.next(true);
              this.dailyReportService.fetchDailyReportById.next(true);
            } else if (action.type === ChangeIssueStatusFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchChangeOrderFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchChangeOrderFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchChangeOrderFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
              };
            });
            const sortedTempData = tempData.sort((a, b) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            return FetchChangeOrderFilterSuccess({
              list: sortedTempData,
            });
          }),
          catchError(() => {
            return of(FetchChangeOrderFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchChangeOrderFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchChangeOrderFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  executeCurrentActionStateRequest$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ExecuteCurrentActionStateRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.executeCurrentActionStateRequest(payload?.executeCurrentActionStatePayload).pipe(
          map(response => {
            const { data } = response;
            return ExecuteCurrentActionStateSuccess({ data });
          }),
          catchError(() => {
            return of(ExecuteCurrentActionStateFailure());
          }),
          tap((action: any) => {
            if (action.type === ExecuteCurrentActionStateSuccess.type) {
              // Code to execute on API Success Action dispatch
              const projectId = payload.fetchNextActionsStatePayload.qp.project_id;
              const siteId = payload.fetchNextActionsStatePayload.qp.site_id;
              const url = `/projects/${projectId}/sites/${siteId}`;
              const qp = {
                tab: 'daily-report',
                reportId: action.data.id,
                date: action.data.report_date,
              };

              this.router.navigate(['/'])
                .then(() => {
                  this.router.navigate([url], {
                    queryParams: qp,
                  });
                });
            } else if (action.type === ExecuteCurrentActionStateFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  addContractorStatus$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddContractorStatusRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.addContractorStatus(payload).pipe(
          map(() => {

            return AddContractorStatusSuccess({});
          }),
          catchError(() => {
            return of(AddContractorStatusFailure());
          }),
          tap((action: any) => {
            if (action.type === AddContractorStatusSuccess.type) {
              // Code to execute on API Success Action dispatch
              // this.commonService.closeDialog.next();
              this.projectsService.addContractorStatusSuccess.next(true);
            } else if (action.type === AddContractorStatusFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  
  private datePipe: DatePipe;
  fetchChangeOrderFilterInSideNav$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchChangeOrderFilterInSideNavRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchChangeOrderFilterInSideNav(payload).pipe(
          map(response => {
            const { data } = response;
            const { change_orders } = data;
            const tempData = change_orders?.map((item) => {
              const formatedDate = this.datePipe.transform(item.created_at, 'MMM d, y');
              return {
                ...item,
                key: item.id,
                value: item.order_number ? `${item.order_number} (${formatedDate})` : `${formatedDate}`,
              };
            });

            const sortedTempData = tempData.sort((a, b) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            return FetchChangeOrderFilterInSideNavSuccess({
              list: sortedTempData,
            });
          }),
          catchError(() => {
            return of(FetchChangeOrderFilterInSideNavFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchChangeOrderFilterInSideNavSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchChangeOrderFilterInSideNavFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
    private issueService: IssueService,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private injector: Injector,
    private dailyReportService: DailyReportService,
  ) {
    this.datePipe = this.injector.get(DatePipe);
  }
}
