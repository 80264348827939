<div *ngIf="parentFormGroup.controls[controlName]"
     [formGroup]="parentFormGroup"
     class="wrapper"
>
  <div class="label">{{ label }}:</div>
  <div class="button-panel flex flex-row flex-wrap justify-start items-center gap-[12px]">
    <div
      (click)="select(option)"
      *ngFor="let option of options"
      [class.selected]="parentFormGroup?.controls[controlName]?.value === option.type"
      class="button"
    >
      {{ option.label }}
    </div>
  </div>
  <mat-error *ngIf="error" class="error">{{ error }}</mat-error>
</div>
