/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[shared] clear state';
export const ClearState = createAction(CLEAR_STATE);


/******** LOADING ***********/
const START_LOADING = '[shared] start loading';
export const StartLoading = createAction(START_LOADING);

const STOP_LOADING = '[shared] stop loading';
export const StopLoading = createAction(STOP_LOADING);

const CLEAR_LOADING = '[shared] clear loading';
export const ClearLoading = createAction(CLEAR_LOADING);


/******** FETCH APP DATA ***********/
const FETCH_APP_DATA_REQUEST = '[shared] fetch app data request';
export const FetchAppDataRequest = createAction(FETCH_APP_DATA_REQUEST);

const FETCH_APP_DATA_SUCCESS = '[shared] fetch app data success';
export const FetchAppDataSuccess = createAction(FETCH_APP_DATA_SUCCESS, props<any>());

const FETCH_APP_DATA_FAILURE = '[shared] fetch app data failure';
export const FetchAppDataFailure = createAction(FETCH_APP_DATA_FAILURE, props<any>());

/******** UPLOAD FILE ***********/
const UPLOAD_FILE_REQUEST = '[projects] upload file report request';
export const UploadFileRequest = createAction(UPLOAD_FILE_REQUEST, props<any>());

const UPLOAD_FILE_SUCCESS = '[projects] upload file report success';
export const UploadFileSuccess = createAction(UPLOAD_FILE_SUCCESS);

const UPLOAD_FILE_FAILURE = '[projects] upload file report failure';
export const UploadFileFailure = createAction(UPLOAD_FILE_FAILURE);
