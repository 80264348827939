import { Injectable } from '@angular/core';
import Extent from '@arcgis/core/geometry/Extent';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {

  initiateSiteLocationsMap: Subject<any> = new Subject();

  constructor() {
  }

  removeAllMarkers(map) {
    map?.mapSiteMarkersGraphicsLayer.removeAll();
    map?.mapStationMarkersGraphicsLayer.removeAll();
  }

  setExtent(map, points, expansionFactor = 1.15) {
    if (points.length) {
      const e = points
          .map(g => g.geometry)
          .reduce(
            (acc, geom) => {
              return {
                xmin: Math.min(acc.xmin, geom.longitude),
                ymin: Math.min(acc.ymin, geom.latitude),
                xmax: Math.max(acc.xmax, geom.longitude),
                ymax: Math.max(acc.ymax, geom.latitude),
              };
            },
            {
              xmin: Number.MAX_SAFE_INTEGER,
              ymin: Number.MAX_SAFE_INTEGER,
              xmax: Number.MIN_SAFE_INTEGER,
              ymax: Number.MIN_SAFE_INTEGER,
            },
          );

      setTimeout(() => {
        if (e && !isNaN(e.xmax) && !isNaN(e.xmin) && !isNaN(e.ymax) && !isNaN(e.ymin)) {
          if (map && map.mapViewInstance && e.xmin !== e.xmax && e.ymin !== e.ymax) {
            try {
              map.mapViewInstance.extent = new Extent(e).expand(expansionFactor);
            }
            catch (e) {
              console.warn(e.message);
            }
          } else {
            map.mapViewInstance.center = points[0].geometry;
            map.mapViewInstance.zoom = 16;
          }
        }
      }, 500);

    }
  }

  createMarker(location, data, type) {
    return {
      geometry: {
        type: 'point',
        longitude: location.longitude,
        latitude: location.latitude,
      },
      symbol: type,
      data,
    };
  }
}
