import { Injectable } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { v1URL } from '../constants/urls.constants';
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private commonServices: CommonService) {}

  fetchAppData(payload: any): Observable<any> {
    const urlConfig = v1URL.appData;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  uploadFile(payload: any): Observable<any> {
    const urlConfig = v1URL.attachment;

    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload, {
      headers: new HttpHeaders({})
    });
  }
}
