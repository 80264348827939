import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DailyReportService } from '../../tab-screens/daily-report/daily-report.service';
import { UploadFileRequest } from '../../../../../../shared/core/shared.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../state/app.state';
import { Subject } from 'rxjs';
import { LocationPickerComponent } from '../../../../../../shared/components/location-picker/location-picker.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { currentLocation, projectDetails, siteDetails } from '../../../../core/projects.selectors';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../../../core/projects.service';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../../../shared/components';
import { ChangeIssueStatusRequest } from '../../../../core/projects.actions';

@Component({
  selector: 'app-actions-section',
  templateUrl: './actions-section.component.html',
  styleUrls: ['./actions-section.component.scss'],
})
export class ActionsSectionComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: false }) fileInputRef: any;
  @Input() disableAddMenu = false;
  @Input() disableOptionMenu = false;
  @Input() hideDeleteOption = false;
  status: any = 'open';
  isFormDirty = false;
  addMenuOptions = [
    {
      key: 'picture',
      icon: 'camera-outline',
      label: 'Picture',
      handler: () => {
        this.fileInputRef.nativeElement.click();
      },
    },
    {
      key: 'location',
      icon: 'marker-outline',
      label: 'Location',
      handler: () => {
        this.store.select(currentLocation)
          .subscribe(location => {
            if (!location?.latitude || !location?.longitude) {
              this.store.select(siteDetails)
                .subscribe(site => {
                  if (site?.longitude && site?.latitude) {
                    location = {
                      latitude: site?.latitiude,
                      longitude: site?.longitude,
                    };
                  } else {
                    this.store.select(projectDetails)
                      .subscribe(project => {
                        if (project?.longitude && project?.latitude) {
                          location = {
                            latitude: project?.latitiude,
                            longitude: project?.longitude,
                          };
                        }
                      }).unsubscribe();
                  }
                }).unsubscribe();
            }

            this.dialog.open(LocationPickerComponent, {
              disableClose: true,
              data: {
                ...location,
                readOnly: false,
              },
            });
          }).unsubscribe();
      },
    },
    {
      key: 'comment',
      icon: 'pencil-outline',
      label: 'Comment',
      handler: () => {
        this.dailyReportService.addComment.next(null);
      },
    },
  ];
  files: any[] = [];
  hideAddOption = false;
  issue: any;
  protected readonly open = open;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private dailyReportService: DailyReportService,
    private store: Store<fromRoot.State>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public projectsService: ProjectsService,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        // tslint:disable-next-line:max-line-length
        if (params && params['expandedSection'] === 'activities' || params.expandedSection === 'issues' || params['expandedSection'] === undefined) {
          this.hideAddOption = true;
          const index = this.addMenuOptions.findIndex(item => item.key === 'comment');
          if (index !== -1) {
            this.addMenuOptions.splice(index, 1);
          }
        }

        if (params.expandedSection === 'delivery-usage' || params.expandedSection === 'labor-equipment' || params.expandedSection === 'weather') {
          this.hideAddOption = true;
        }

        if (params.expandedSection === 'issues' || params['expandedSection'] === undefined) {
          this.disableOptionMenu = true;
        }
      });

    this.projectsService.isAnyFormDirty
      .pipe(takeUntil(this.onDestroy))
      .subscribe((dirty) => {
        this.isFormDirty = dirty;
      });

    this.dailyReportService.disableOptionMenu
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.disableOptionMenu = true;
      });

    this.dailyReportService.issue
      .pipe(takeUntil(this.onDestroy))
      .subscribe((issue) => {
        this.issue = issue;
      });
  }

  ngOnInit() {


  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    const formData = new FormData();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      formData.append('inputFiles', files.item(i));
    }

    this.store.dispatch(UploadFileRequest({ payload: formData }));
    this.fileInputRef.nativeElement.value = '';
  }

  save() {
    this.dailyReportService.triggerSave.next(null);
  }

  addNew() {
    this.dailyReportService.add.next();
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        `Are you sure you want to delete this item?`,
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dailyReportService.triggerDelete.next(null);
      }
    });
  }

  setStatus(data) {
    const { status, ...rest } = this.issue;
    const payload = {
      ...rest,
      status: data,
      qp: {
        include: [
          'createdBy',
          'bid_item',
          'entity',
          'pictures',
          'attachments',
          'material_tests',
        ],
      },
    };
    this.store.dispatch(ChangeIssueStatusRequest({ payload }));
  }

  handleLocation() {
    let location = {};
    navigator.geolocation.getCurrentPosition(
      (position) => {
        location = {
          longitude: position?.coords?.longitude || null,
          latitude: position?.coords?.latitude || null,
        };
        this.openLocationPicker(location);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          this.store.select(siteDetails)
            .subscribe(site => {
              if (site?.longitude && site?.latitude) {
                location = {
                  latitude: site?.latitiude,
                  longitude: site?.longitude,
                };
                this.openLocationPicker(location);
              } else {
                this.store.select(projectDetails)
                  .subscribe(project => {
                    if (project?.longitude && project?.latitude) {
                      location = {
                        latitude: project?.latitiude,
                        longitude: project?.longitude,
                      };
                      this.openLocationPicker(location);
                    }
                  }).unsubscribe();
              }
            }).unsubscribe();
        }
      });

  }

  openLocationPicker(location) {
    this.dialog.open(LocationPickerComponent, {
      disableClose: true,
      data: {
        ...location,
        readOnly: false,
      },
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
