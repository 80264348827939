import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DailyReportService } from '../../../../daily-report.service';
import { takeUntil } from 'rxjs/operators';
import { allBidItemsFilter, issueTypesFilter, projectDetails } from '../../../../../../../../core/projects.selectors';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import {
  AddIssuesDetailsRequest,
  FetchAllBidItemsRequest,
  UpdateIssuesDetailsRequest,
} from '../../../../../../../../core/projects.actions';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';

@Component({
  selector: 'app-issue-details-form',
  templateUrl: './issue-details-form.component.html',
  styleUrls: ['./issue-details-form.component.scss'],
})
export class IssueDetailsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any;

  form = new FormGroup({
    title: new FormControl({ value: null, disabled: false }),
    description: new FormControl({ value: null, disabled: false }),
    projectScope: new FormControl({ value: null, disabled: false }),
    bidItem: new FormControl({ value: null, disabled: false }),
    type: new FormControl({ value: null, disabled: false }, { validators: [Validators.required] }),
    internal: new FormControl({ value: true, disabled: false }),
    latitude: new FormControl(null),
    longitude: new FormControl(null),
    pictures: new FormControl('[]'),
  });

  errorMessages: any = {};
  errorMessageMap = {
    bidItem: {
      required: 'This field is required.',
    },
    type: {
      required: 'This field is required.',
    },
  };

  formSubscription: Subscription = new Subscription();
  projectScopeOptions: any = [];
  bidItemOptions: any = [];
  projectId: string;
  pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 10000,
  };
  categoryOptions: any[] = [];
  pictures = [];
  segment: string;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private commonService: CommonService,
    private dailyReportService: DailyReportService,
    private cdRef: ChangeDetectorRef,
  ) {
    const urlWithoutQp = this.router.url.split('?')[0];
    const segments = urlWithoutQp.split('/');
    if (urlWithoutQp.includes('issues')) {
      this.segment = segments[3].split('?')[0];
    }
    this.projectId = urlWithoutQp.includes('issues') ? this.segment : segments[2];

    const payload = {
      projectId: this.projectId,
      qp: {
        include: [
          {
            bid_items: ['fields'],
          },
        ],
      },
    };
    this.store.dispatch(FetchAllBidItemsRequest({ payload }));

    this.store.select(issueTypesFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data.length > 0) {
          this.categoryOptions = data;
        } else {
          this.categoryOptions = [
            {
              value: 'uSZJ8f1DFVpUHjB38x9vEW',
              label: 'Standard',
              id: 'uSZJ8f1DFVpUHjB38x9vEW',
              type: 'standard',
            },
            {
              value: '8EUNXG7Xfq1Mp1Tn13pki4',
              label: 'Environment',
              id: '8EUNXG7Xfq1Mp1Tn13pki4',
              type: 'environment',
            },
            {
              value: 'oYGCxpAmv8Viev4BA3Mp83',
              label: 'Safety',
              id: 'oYGCxpAmv8Viev4BA3Mp83',
              type: 'safety',
            },
            {
              value: '8ZBH7RcGikKitWkJ3DfgMG',
              label: 'As built',
              id: '8ZBH7RcGikKitWkJ3DfgMG',
              type: 'as-built',
            },
          ];
        }
      });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.projectsService.isAnyFormDirty.next(true);
      });
  }

  ngOnInit() {
    this.store.select(allBidItemsFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((bidItems) => {
        this.bidItemOptions = bidItems;
      });

    if (this.data?.id) {
      this.form.patchValue({
        title: this.data?.title,
        description: this.data?.description,
        type: this.data?.type,
        internal: this.data?.internal,
        projectScope: this.data?.entity?.id,
        bidItem: this.data?.bid_item?.id,
        latitude: this.data?.latitude,
        longitude: this.data?.longitude,
        pictures: JSON.stringify(this.data?.pictures),
      }, { emitEvent: false });
      this.pictures = this.data.pictures;
      this.dailyReportService.hideDeleteOption.next(true);
      this.dailyReportService.issue.next(this.data);
    } else {
      this.dailyReportService.disableOptionMenu.next(true);
    }

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.save();
      });

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        let pictures: any = this.form.get('pictures')?.value || '[]';

        if (pictures) {
          pictures = JSON.parse(pictures);

          pictures = [...pictures, ...data];
        } else {
          pictures = [...data];
        }

        this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
        this.pictures = pictures;
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        this.form.patchValue({
          latitude: location.latitude,
          longitude: location.longitude,
        });
        this.cdRef.detectChanges();
      });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.projectScopeOptions = [
          {
            value: `${data?.id}`,
            label: `Project ${data?.name}`,
            type: 'project',
          },
          ...data?.sites.map(o => ({
            value: `${o.id}`,
            label: `Site ${o.name}`,
            type: 'site',
          })),
        ];
      });
  }

  save() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const values = this.form.getRawValue();
    const { pictures, bidItem, projectScope, ...rest } = values;
    const tempData = JSON.parse(pictures).map(item => item.id);
    const tempBidItemData = this.bidItemOptions?.find(i => i.id === bidItem);
    const entityType = this.projectScopeOptions?.find(i => (i.id || i.value) === projectScope);

    const payload = {
      ...this.data,
      ...rest,
      pictures: tempData,
      bid_item: tempBidItemData,
      bid_item_id: tempBidItemData?.id,
      entity_id: projectScope,
      entity_type: entityType?.type,
    };

    if (payload.id) {
      payload.qp = {
        include: [
          'createdBy',
          'bid_item',
          'entity',
          'pictures',
          'attachments',
          'material_tests',
        ],
      };
      this.store.dispatch(UpdateIssuesDetailsRequest({ payload }));
    } else {
      this.store.dispatch(AddIssuesDetailsRequest({ payload }));
    }
  }

  onToggle(event: MatLegacySlideToggleChange) {
    this.form.controls['internal'].setValue(event.checked);
  }

  deletePicture(id) {
    let pictures = this.getPictures();

    pictures = pictures?.filter(o => o.id !== id);

    this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
    this.pictures = pictures;
  }

  deleteLocation() {
    this.form?.get('latitude').setValue(null);
    this.form?.get('longitude').setValue(null);
  }

  showLocationSection() {
    return this.form?.controls?.latitude?.value !== null &&
      this.form?.controls?.longitude?.value !== null &&
      this.form?.controls?.latitude?.value !== '' &&
      this.form?.controls?.longitude?.value !== '';
  }

  getPictures() {
    const pictures = this.form.controls['pictures']?.value || '[]';
    return JSON.parse(pictures);
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

}
