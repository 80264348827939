import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import { FetchProjectsListRequest } from '../../../core/projects.actions';
import { projectsList } from '../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ProjectsService } from '../../../core/projects.service';
import { isLoading } from '../../../../../shared/core/shared.selectors';
import { CommonService } from '../../../../../shared/services/common.service';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss'],
})
export class ProjectsPageComponent implements OnDestroy {
  initialized = false;
  projects: any[] = [];
  filteredProjects: any[] = [];
  paginatedFilteredProjects: any[];
  // USED FOR API CALL
  pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 1000,
  };
  itemsPerPage = 5;
  currentPage = 1;
  isLoading = false;
  frequency = 'Recently updated';

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private commonService: CommonService,
  ) {
    this.store.select(isLoading)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(loading => {
        this.isLoading = !!loading?.length;
      });

    this.store.dispatch(
      FetchProjectsListRequest({
        payload: {
          qp: {
            start: this.pagination.pageIndex * this.pagination.pageSize,
            total: this.pagination.pageSize,
            all: 'all',
            include: [
              'sites',
              'cover_photo',
              'status',
              'summary',
            ],
          },
        },
      }),
    );

    this.store
      .select(projectsList)
      .pipe(
        takeUntil(this.onDestroy))
      .subscribe(data => {
        this.projects = data;
        this.filteredProjects = this.projects;
        this.filterProjects();
      });

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {

        if (qp.frequency && qp.frequency === '0' || qp.frequency === '-1') {
          this.frequency = qp.frequency !== '-1' ? 'Recently updated' : 'All';
          this.filterProjects();
        } else {
          this.router.navigate([], {
            queryParams: {
              frequency: this.frequency !== 'All' ? 0 : -1,
            },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  filterProjects() {
    this.filteredProjects = this.projects.filter((project) => {
      if (this.frequency === 'All') {
        return true;
      } else {
        return moment(project?.summary?.last_updated_at).isAfter(moment().subtract(30, 'days'));
      }
    })
      .sort((a, b) => {
        const maxDateA = moment(a?.summary?.last_updated_at);
        const maxDateB = moment(b?.summary?.last_updated_at);
        // @ts-ignore
        return maxDateB - maxDateA;
      });

    this.updateFilteredProjectList();
  }

  setFrequency(value) {
    this.currentPage = 1;
    this.commonService.resetPaginator.next(true);

    this.router.navigate([], {
      queryParams: {
        frequency: value !== 'All' ? 0 : -1,
      },
    });

    this.filterProjects();
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.updateFilteredProjectList();
  }

  updateFilteredProjectList(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.filteredProjects.length);
    this.paginatedFilteredProjects = this.filteredProjects?.slice(startIndex, endIndex);
    this.projectsService.filteredProjectsForMap.next(this.paginatedFilteredProjects);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
