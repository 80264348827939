<app-map-tooltip
  style="position: relative"
  id="tooltip"
  *ngIf="showTooltip"
  [screenPoint]="screenPoint"
  [tooltipContent]="tooltipContent"
/>

<app-esri-map
  #projectMap
  id="projectMap"
  class="project-map"
  [allowMarkerClick]="true"
  (markerClick)="onMarkerClick($event)"
  (markerHover)="_onShowTooltip($event)"
  (mapLoaded)="onMapLoaded($event)"
  (mapComponentLoaded)="onMapComponentLoaded($event)"
  (viewLoaded)="onViewLoaded($event)"
></app-esri-map>
