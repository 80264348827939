<button
  mat-icon-button
  aria-label="Close"
  class="close"
  mat-dialog-close
  (click)="close($event)"
>
  <mat-icon title="Close">close</mat-icon>
</button>
<swiper
  [slidesPerView]="1"
  [navigation]="true"
  [preloadImages]="false"
  [lazy]="true"
  [pagination]="{ clickable: true }"
  [initialSlide]="initialSlide"
  [keyboard]="true"
>
  <ng-template swiperSlide *ngFor="let att of attachments">
    <div class="slide-header">
      <button
        mat-icon-button
        aria-label="Download {{ att.original_name }}"
        title="Download {{ att?.original_name }} ({{
          att?.size | humanReadableSize
        }})"
        (click)="download(att)"
      >
        <mat-icon>download</mat-icon>
      </button>
    </div>

    <div class="slide-content">
      <img
        [attr.data-src]="att.preview || att.preview_url"
        alt="Preview of {{ att.original_name }}"
        class="swiper-lazy slide-image"
      />
      <div class="swiper-lazy-preloader"></div>
    </div>
  </ng-template>
</swiper>
