<div class="wrapper">
  <div class="quantity-input">
    <app-number-input
      [parentFormGroup]="form"
      controlName="quantity"
      [label]="'USED (' + uom + ')'"
      [error]="form.controls.quantity.errors?.required ? 'This field is required' : null"
    />
  </div>
  <div
    *ngIf="!!form.controls['comment']"
    class="comment-section"
  >
    <app-text-area
      [parentFormGroup]="form"
      controlName="comment"
      label="COMMENT"
    />
  </div>
  <app-pictures-section
    *ngIf="selectedPictures.length"
    class="pictures-section"
    [pictures]="selectedPictures"
    (onDelete)="deletePicture($event)"
  />
  <app-location-section
    *ngIf="showLocationSection()"
    class="location-section"
    [latitude]="form?.controls?.latitude?.value"
    [longitude]="form?.controls?.longitude?.value"
    (onDelete)="deleteLocation()"
  />
</div>
