import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {DailyReportService} from '../../../../daily-report.service';
import {CommonService} from '../../../../../../../../../../shared/services/common.service';
import {dailyReport, projectDetails} from '../../../../../../../../core/projects.selectors';
import {takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../../../../../../core/projects.service';
import {AppService} from '../../../../../../../../../../shared/services';
import {logging} from 'protractor';

@Component({
  selector: 'app-usage-details',
  templateUrl: './usage-details.component.html',
  styleUrls: ['../../input-component-styles.scss', './usage-details.component.scss']
})
export class UsageDetailsComponent implements OnChanges, OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() data: any;

  form = new FormGroup({});

  materialForm = new FormGroup({
    materials: new FormControl([])
  });

  materialOptions = [];

  selectedMaterialId: string;
  selectedPictures: any[] = [];

  project: any;
  selectedBidItem: any;

  showHeaderDetails = true;

  linesToDelete: any[] = [];

  formSubscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private dailyReportService: DailyReportService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private projectsService: ProjectsService,
    public appService: AppService,
  ) {
  }

  ngOnInit() {
    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.project = data;

        if (this.data?.subType === 'bidItemWithMaterials') {
          this.selectedBidItem = data.bid_items.find(o => o.id === this.data.initialState.bid_item_id);
          this.updateMaterialsDropdown();
        }
      });

    this.materialForm.controls.materials.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data: any) => {
        const allMaterialIds = this.data.materials.map(o => o.initialState.material.id);
        if (data.length > this.data.materials.length) {
          const extraElement = data.filter(x => !allMaterialIds.includes(x))[0];
          this.addMaterialToBidItem(extraElement);
        }
      });


    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        let pictures = this.getFormGroup(this.form, this.selectedMaterialId)?.get('pictures')?.value || '[]';

        if (pictures) {
          pictures = JSON.parse(pictures);

          pictures = [...pictures, ...data];
        } else {
          pictures = [...data];
        }

        this.getFormGroup(this.form, this.selectedMaterialId)?.get('pictures')?.setValue(JSON.stringify(pictures));
        this.selectedPictures = this.getSelectedPictures() || [];
      });

    this.dailyReportService.addComment
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const tempControl = this.getFormGroup(this.form, this.selectedMaterialId).get('comment');

          if (!tempControl) {
            this.getFormGroup(this.form, this.selectedMaterialId)?.addControl('comment', new FormControl(null));
          }
        }
      });

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.dailyReportService.formatAndSaveUsage(this.form.getRawValue(), this.data, this.linesToDelete);
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.store.select(dailyReport)
          .subscribe(report => {
            const {material_deliveries} = report;

            const usageItem = this.data;

            const elementIds = [];

            material_deliveries.forEach(usage => {
              if (usageItem.subType === 'bidItemWithMaterials') {

                if (
                  usage.bid_item !== null &&
                  usage.material !== null &&
                  usage.received === null &&
                  usage.used !== null &&
                  usage.quantity === 0
                ) {
                  elementIds.push(usage.id);
                }

                if (
                  usage.bid_item !== null &&
                  (usage.material !== null || true) &&
                  usage.received === null &&
                  usage.used === null &&
                  usage.quantity === 0
                ) {
                  elementIds.push(usage.id);
                }
              }

              if (usageItem.subType === 'onlyBidItem') {
                if (
                  usage.bid_item !== null &&
                  usage.material === null &&
                  usage.received === null &&
                  usage.used !== null &&
                  usage.quantity === 0
                ) {
                  elementIds.push(usage.id);
                }
              }

              if (usageItem.subType === 'onlyMaterial') {
                if (
                  usage.bid_item === null &&
                  usage.material !== null &&
                  usage.received === null &&
                  usage.used !== null &&
                  usage.quantity === 0
                ) {
                  elementIds.push(usage.id);
                }
              }

            });

            this.dailyReportService.delete.next({
              type: 'delivery',
              id: elementIds
            });
          }).unsubscribe();
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        if (this.form) {
          this.getFormGroup(this.form, this.selectedMaterialId)?.patchValue(location);
        }
      });
  }

  ngOnChanges() {
    this.linesToDelete = [];
    if (this.data) {
      this.createForm();
    }
  }

  createForm() {
    const values = this.form.getRawValue();
    this.form = new FormGroup({});

    const parentFormBuilder = {};

    parentFormBuilder[this.data.id] = this.createLineItemForm(this.fb.group({}), this.data);

    if (this.data?.materials?.length > 0) {
      this.data?.materials.map(material => {
        parentFormBuilder[material.id] = this.createLineItemForm(this.fb.group({}), material);
      });
    }

    this.form = new FormGroup(parentFormBuilder);

    this.form.patchValue(values, {emitEvent: false});

    this.selectedMaterialId = this.data.id;

    this.selectedPictures = this.getSelectedPictures();

    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form.valid) {
          this.projectsService.isAnyFormDirty.next(true);
        } else {
          this.projectsService.isAnyFormDirty.next(false);
        }
      });
  }

  createLineItemForm(form, data) {
    if (data.subType !== 'bidItemWithMaterials') {
      form.addControl('quantity', new FormControl(data.quantity, [Validators.required]));
    }

    if (data.comment) {
      form.addControl('comment', new FormControl(data.comment));
    }

    if (data.fieldValues) {
      data.fieldValues.map(o => {
        form.addControl(o.id, new FormControl(o.value));
      });
    }

    form.addControl('pictures', new FormControl(JSON.stringify(data.pictures)));

    form.addControl('latitude', new FormControl(data.latitude));
    form.addControl('longitude', new FormControl(data.longitude));

    return form;
  }

  getFormGroup(form, controlName) {
    if (form && form.get(controlName)) {
      return form.get(controlName) as FormGroup;
    }

    return new FormGroup({}) as FormGroup;
  }

  getSelectedPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedMaterialId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  selectLineItem(lineItemId) {
    this.selectedMaterialId = this.selectedMaterialId === lineItemId ? this.data.id : lineItemId;

    this.showHeaderDetails = this.selectedMaterialId === this.data.id;

    this.selectedPictures = this.getSelectedPictures();
  }

  deleteLineItem(lineItemId) {
    if (!lineItemId.includes('new_')) {
      this.linesToDelete.push(lineItemId);
    }

    this.form.removeControl(lineItemId);
    this.data.materials = this.data?.materials?.filter(o => o.id !== lineItemId);

    if (this.selectedMaterialId === lineItemId) {
      this.selectLineItem(this.data.id);
    }
  }

  deletePicture(id) {
    let pictures = this.getSelectedPictures();

    pictures = pictures?.filter(o => o.id !== id);

    this.getFormGroup(this.form, this.selectedMaterialId)?.get('pictures').setValue(JSON.stringify(pictures));
    this.selectedPictures = pictures;
  }

  deleteLocation(lineItemId) {
    this.getFormGroup(this.form, lineItemId)?.get('latitude').setValue(null);
    this.getFormGroup(this.form, lineItemId)?.get('longitude').setValue(null);
  }

  showLocationSection(lineItemId) {
    return this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== '' &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== '';
  }

  updateMaterialsDropdown() {
    const selectedMaterials = this.data.materials.map(m => m.initialState.material.id);

    this.materialForm.patchValue({materials: selectedMaterials}, {emitEvent: false});

    this.materialOptions = this.selectedBidItem.materials.filter(o => o.track_received).map(o => ({
      label: `${o.name} (${o.uom})`,
      value: o.id,
      disabled: selectedMaterials.includes(o.id)
    }));
  }

  addMaterialToBidItem(materialId) {
    const selectedMaterial = this.selectedBidItem.materials.find(o => o.id === materialId);


    const id = `new_${new Date().getTime()}`;

    this.data.materials.push({
      id,
      heading: selectedMaterial.name,
      uom: selectedMaterial.uom,
      quantity: null,
      latitude: null,
      longitude: null,
      pictures: [],
      comment: '',
      type: 'delivery',
      subType: 'onlyMaterial',
      initialState: {
        material: selectedMaterial,
        bid_item_id: this.selectedBidItem.id,
      }
    });

    this.createForm();

    this.updateMaterialsDropdown();

    this.selectLineItem(id);
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

