import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { DailyReportService } from '../../../../daily-report.service';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { dailyReport, projectDetails } from '../../../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { DeliveryDetailsWrapperComponent } from '../delivery-details-wrapper/delivery-details-wrapper.component';

@Component({
  selector: 'app-add-delivery-details',
  templateUrl: './add-delivery-details.component.html',
  styleUrls: ['./add-delivery-details.component.scss'],
})
export class AddDeliveryDetailsComponent implements OnInit, OnDestroy {
  elementsList: any[] = [];
  filteredElementsList: any[] = [];
  showList = false;
  form = new FormGroup({
    searchText: new FormControl(''),
  });
  searchText = '';
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private dailyReportService: DailyReportService,
    private projectsService: ProjectsService,
  ) {
  }

  ngOnInit() {
    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(project => {
        if (project) {
          const deliveryBidItems = project?.bid_items?.filter(o => o.track_material_quantity || o.materials.length > 0) || [];
          const deliveryMaterials = project?.materials?.filter(o => o.track_received) || [];

          this.elementsList = [...deliveryMaterials, ...deliveryBidItems];

          this.filteredElementsList = this.elementsList;
        }
      });

    this.form.controls.searchText.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(searchText => {
        this.searchText = searchText;
        if (searchText) {
          this.filteredElementsList = this.elementsList.filter(item => {
            let keywordsArr = [];

            keywordsArr.push(item.name, item.item, item.description);

            if (item.materials?.length > 0) {
              item.materials?.forEach(o => {
                keywordsArr.push(o.name);
              });
            }

            keywordsArr = keywordsArr.filter(o => !!o);

            const filterString = keywordsArr.join('');

            if (filterString?.toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }

          });
        } else {
          this.searchText = '';
          this.filteredElementsList = this.elementsList;
        }
      });
  }

  addDelivery(deliveryItem) {
    this.store.select(dailyReport)
      .subscribe(report => {
        const { reportDetails } = report;

        const deliveries = reportDetails?.deliveries;

        const foundElement = deliveries.find(delivery => {

          if (deliveryItem.track_material_quantity !== undefined) {
            // Bid Item with materials
            if (deliveryItem?.id === delivery.initialState?.bid_item_id && deliveryItem.materials?.length) {
              return true;
            }

            // Only bid item
            if (deliveryItem?.id === delivery.initialState?.bid_item_id && !deliveryItem.materials?.length) {
              return true;
            }
          } else {
            // Only Material
            if (delivery?.initalState?.material?.id === deliveryItem.id) {
              return true;
            }
          }
        });


        const element = foundElement ?
          JSON.parse(JSON.stringify(foundElement)) :
          this.dailyReportService.createNewDeliveryBlock(deliveryItem);

        this.projectsService.openRightPanel.next({
          component: DeliveryDetailsWrapperComponent,
          data: element,
        });

      }).unsubscribe();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
