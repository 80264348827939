import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/state/app.state';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as $ from 'jquery';
import { AddContractorStatusRequest } from 'src/app/modules/projects/core/projects.actions';
import { UploadFileRequest } from 'src/app/shared/core/shared.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-reason',
  templateUrl: './add-reason.component.html',
  styleUrls: ['./add-reason.component.scss'],
})
export class AddReasonComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploadRef') fileUploadRef: ElementRef;

  errorMessages: any = {};
  errorMessageMap = {
    comment: {
      required: 'This field is required.',
    },
    quantity: {
      required: 'This field is required.',
    },
  };

  form = this.fb.group({
    quantity: new FormControl({ value: '', disabled: false }),
    comment: new FormControl({ value: '', disabled: false }),
    pictures: new FormControl('[]'),
  });

  file: any;
  errors: any;
  dailyReportId: any;
  reportBidItemId: any;
  // }
  pictures = [];
  projectId: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {


  }

  ngOnInit() {
    this.dailyReportId = this.data.selectedRowData.dailyReportId;
    this.reportBidItemId = this.data.selectedRowData.id;
    this.form.patchValue({
      quantity: this.data.selectedRowData.contractor_suggested_quantity,
      comment: this.data.selectedRowData.contractor_comments,
      pictures: JSON.stringify(this.data.selectedRowData.contractor_pictures),
    });
    this.pictures = this.data.selectedRowData.contractor_pictures;

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        let pictures: any = this.form.get('pictures')?.value || '[]';

        if (pictures) {
          pictures = JSON.parse(pictures);

          pictures = [...pictures, ...data];
        } else {
          pictures = [...data];
        }

        this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
        this.pictures = pictures;
        this.form.markAsDirty();
      });
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }

  deletePicture(id) {
    let pictures = this.getPictures();

    pictures = pictures?.filter(o => o.id !== id);

    this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
    this.pictures = pictures;
    this.form.markAsDirty();
  }

  getPictures() {
    const pictures = this.form.controls['pictures']?.value || '[]';
    return JSON.parse(pictures);
  }

  onSubmit() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      comment,
      quantity,
      pictures,
    } = this.form.getRawValue();


    const picturesIdArray = [];
    JSON.parse(pictures).map((picture) => {
      picturesIdArray.push(picture.uuid || picture.id);
    });

    const payload = {
      comments: comment,
      suggested_quantity: quantity,
      reportBidItemId: this.reportBidItemId,
      dailyReportId: this.dailyReportId,
      status: this.data.status === 1 ? 'agree' : 'disagree',
      pictures: picturesIdArray,
    };
  
    this.store.dispatch(AddContractorStatusRequest({ payload }));
    const resultData = {
      suggested_quantity: payload.suggested_quantity,
      comments: payload.comments,
      pictures: payload.pictures 
    };
    this.dialogRef.close(resultData);  
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    const formData = new FormData();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      formData.append('inputFiles', files.item(i));
    }

    this.store.dispatch(UploadFileRequest({ payload: formData }));
  }

  openFile(id) {
    $(`#${id}`).click();
  }

  closeDialog() {
    this.dialogRef.close();  
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
