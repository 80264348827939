import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { FetchProjectReportRequest, FetchProjectReportsListRequest } from '../../../../../../core/projects.actions';
import { projectDetails, projectReport, projectReportsList } from '../../../../../../core/projects.selectors';

@Component({
  selector: 'app-consolidated-report-wrapper',
  templateUrl: './consolidated-report-wrapper.component.html',
  styleUrls: ['./consolidated-report-wrapper.component.scss'],
})
export class ConsolidatedReportWrapperComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    date: new FormControl(),
  });

  projectId = '';
  projectReport: any;
  projectReportsList: any[] = [];
  isInitial = true;
  protected readonly Object = Object;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.date && moment(qp.date).isValid() && this.form.controls.date.value !== moment(qp.date)) {
          this.form.controls.date.setValue(moment(qp.date, 'YYYY-MM-DD'));
          this.fetchProjectReport();
        } else {
          this.router.navigate([], {
            queryParams: {
              date: null,
            },
            queryParamsHandling: 'merge',
          });
        }
      });

    this.route.params
      .pipe(takeUntil(this.onDestroy))
      .subscribe(p => {
        this.projectId = p.projectId;
        this.fetchProjectReport();
      });

    this.form.controls.date.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(date => {
        this.router.navigate([], {
          queryParams: {
            date: moment(date).format('YYYY-MM-DD'),
          },
          queryParamsHandling: 'merge',
        });
      });

    this.store.select(projectReport)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.projectReport = data;
        this.cdRef.detectChanges();
      });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(project => {
        if (Object.keys(project).length !== 0 && this.isInitial) {
          this.store.dispatch(FetchProjectReportsListRequest({
            payload: {
              qp: {
                projectId: project.id,
                from: project.start_date,
                to: moment().format('YYYY-MM-DD'),
                include: ['status'],
                total: 100000,
              },
            },
          }));

          this.isInitial = false;
        }
      });

    this.store.select(projectReportsList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(projectReports => {
        this.projectReportsList = projectReports.filter(o => o.status === 'submitted');
      });
  }

  fetchProjectReport() {
    if (this.projectId && this.form.controls.date.value) {
      this.store.dispatch(FetchProjectReportRequest({
        payload: {
          qp: {
            date: moment(this.form.controls.date.value).format('YYYY-MM-DD'),
            total: 1,
            projectId: this.projectId,
          },
        },
      }));
    }
  }

  /**
   * Callback after project-report workflow action is performed
   * @param wfResp any
   */
  projectReportChanged(wfResp: any) {
    if (wfResp.data?.id) {
      this.fetchProjectReport();
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
