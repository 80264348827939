<div class="wrapper flex flex-col gap-[40px]">
  <div class="heading text-type-2">Activity</div>
  <app-text-area
    [parentFormGroup]="form"
    controlName="title"
    label="detail"
  />
  <app-pictures-section
    (onDelete)="deletePicture($event)"
    *ngIf="pictures?.length"
    [pictures]="pictures"
    class="pictures-section mb-[40px]"
  />
  <app-location-section
    (onDelete)="deleteLocation()"
    *ngIf="showLocationSection()"
    [latitude]="form?.controls?.latitude?.value"
    [longitude]="form?.controls?.longitude?.value"
    class="location-section mb-[40px]"
  />
  <app-actions-section
    [disableOptionMenu]="disableOptionMenu"
    class="actions-section w-[455px]"
  />
</div>
