import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Parser } from 'expr-eval';
import { DailyReportService } from '../../../../daily-report.service';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import * as moment from 'moment';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import * as $ from 'jquery';
import { AppService } from '../../../../../../../../../../shared/services';

@Component({
  selector: 'app-multiline-bid-item-details',
  templateUrl: './multiline-bid-item-details.component.html',
  styleUrls: ['../../input-component-styles.scss', './multiline-bid-item-details.component.scss'],
})
export class MultilineBidItemDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lineItems: any[];
  @Input() bidItem: any;
  quantityTotal: number = null;
  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();
  isFormReady = false;
  autoCalculateOptions = [
    {
      label: 'Dynamic rollup',
      value: 'dynamic_rollup',
    },
    {
      label: 'Manual entry',
      value: 'manual_entry',
    },
  ];
  selectedLineItemId = '';
  selectedLineItemPictures = [];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dailyReportService: DailyReportService,
    private commonService: CommonService,
    private projectsService: ProjectsService,
    protected appService: AppService,
  ) {
    this.dailyReportService.add
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const id = `new_${new Date().getTime()}`;
          this.selectedLineItemId = id;
          this.dailyReportService.disableAddMenu.next(false);

          const newElement = {
            id,
            bid_item_id: this.bidItem.id,
            created_at: moment().toISOString(),
            bid_item: this.bidItem,
            quantity: null,
            comment: '',
            station_id: null,
            latitude: '',
            longitude: '',
            heading_id: null,
            start_of_work: false,
            station_break: false,
            updated_at: null,
            pictures: [],
            station: null,
            heading: null,
            annotations: [],
            ...(this.bidItem.rollup ?
              {
                field_values: this.bidItem?.fields?.map(o => ({
                    id: o.id,
                    value: null,
                  }),
                ),
              } : {}),

          };

          this.addNewLineItem(newElement);
        }

      });
  }

  ngOnInit() {
    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        let pictures = this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures')?.value || '[]';

        if (pictures) {
          pictures = JSON.parse(pictures);

          pictures = [...pictures, ...data];
        } else {
          pictures = [...data];
        }

        this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures')?.setValue(JSON.stringify(pictures));
        this.selectedLineItemPictures = this.getSelectedLineItemPictures() || [];
      });

    this.dailyReportService.addComment
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const tempControl = this.getFormGroup(this.form, this.selectedLineItemId).get('comment');

          if (!tempControl) {
            this.getFormGroup(this.form, this.selectedLineItemId)?.addControl('comment', new FormControl(null));

            setTimeout(() => {
              $(`#${this.selectedLineItemId} textarea`).focus();
            }, 100);
          }
        }
      });

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.dailyReportService.formatAndSaveMultilineBidItemPayload(this.form, this.bidItem, this.lineItems);
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.bidItem) {
          this.dailyReportService.delete.next({
            type: 'bidItems',
            id: this.bidItem.id,
          });
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        if (this.form) {
          this.getFormGroup(this.form, this.selectedLineItemId)?.patchValue(location);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dailyReportService.disableAddMenu.next(true);
    this.projectsService.isAnyFormDirty.next(false);
    this.formSubscription?.unsubscribe();

    this.isFormReady = false;

    if (this.lineItems && this.lineItems.length > 0) {
      this.form = this.createLineItemForm(this.bidItem, this.lineItems);

      this.form = this.calculateLineItemQuantity(this.form, this.bidItem, true);

      this.quantityTotal = this.calculateTotalQuantity(this.form);

      this.formSubscription = this.form.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.form = this.calculateLineItemQuantity(this.form, this.bidItem);
          this.quantityTotal = this.calculateTotalQuantity(this.form);

          this.projectsService.isAnyFormDirty.next(true);
        });
    } else {
      this.dailyReportService.add.next();
    }

    this.isFormReady = true;
  }

  getFormGroup(form, controlName) {
    if (form && form.get(controlName)) {
      return form.get(controlName) as FormGroup;
    }

    return new FormGroup({}) as FormGroup;
  }

  getFieldValuesMap(values) {
    const mapObj = {};

    this.bidItem.fields.forEach(field => {
      mapObj[field.name] = Number(values[field.id]);
    });

    return mapObj;
  }

  selectLineItem(lineItemId) {
    this.selectedLineItemId = this.selectedLineItemId === lineItemId ? '' : lineItemId;
    this.selectedLineItemPictures = this.getSelectedLineItemPictures();

    if (this.selectedLineItemId) {
      this.dailyReportService.disableAddMenu.next(false);
    } else {
      this.dailyReportService.disableAddMenu.next(true);
    }
  }

  createLineItemForm(bidItem, lineItems) {
    if (bidItem.rollup === false) {
      const parentFormBuilder = {};
      lineItems?.map(lineItem => {
        parentFormBuilder[lineItem.id] = this.fb.group({});

        parentFormBuilder[lineItem.id].addControl('quantity', new FormControl(lineItem.quantity));

        if (lineItem.comment) {
          parentFormBuilder[lineItem.id].addControl('comment', new FormControl(lineItem.comment));
        }

        parentFormBuilder[lineItem.id].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures)));

        parentFormBuilder[lineItem.id].addControl('latitude', new FormControl(lineItem.latitude));
        parentFormBuilder[lineItem.id].addControl('longitude', new FormControl(lineItem.longitude));
      });

      return this.fb.group(parentFormBuilder);
    } else if (bidItem.rollup === true) {
      if (bidItem.rollup_quantity_editable === false) {
        const parentFormBuilder = {};
        lineItems?.map(lineItem => {
          parentFormBuilder[lineItem.id] = this.fb.group({});

          lineItem.field_values?.map(field => {
            parentFormBuilder[lineItem.id].addControl(field.id, new FormControl(field.value));
          });

          parentFormBuilder[lineItem.id].addControl('quantity', new FormControl(null));

          if (lineItem.comment) {
            parentFormBuilder[lineItem.id].addControl('comment', new FormControl(lineItem.comment));
          }

          parentFormBuilder[lineItem.id].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures)));

          parentFormBuilder[lineItem.id].addControl('latitude', new FormControl(lineItem.latitude));
          parentFormBuilder[lineItem.id].addControl('longitude', new FormControl(lineItem.longitude));
        });

        return this.fb.group(parentFormBuilder);
      } else if (bidItem.rollup_quantity_editable === true) {
        const parentFormBuilder = {};
        this.lineItems?.map(lineItem => {
          parentFormBuilder[lineItem.id] = this.fb.group({});

          lineItem.field_values?.map(field => {
            parentFormBuilder[lineItem.id].addControl(field.id, new FormControl(field.value));
          });
          parentFormBuilder[lineItem.id].addControl('initialQuantity', new FormControl(lineItem.quantity));
          parentFormBuilder[lineItem.id].addControl('quantity', new FormControl(lineItem.quantity));
          parentFormBuilder[lineItem.id].addControl('valueType', new FormControl('dynamic_rollup'));

          if (lineItem.comment) {
            parentFormBuilder[lineItem.id].addControl('comment', new FormControl(lineItem.comment));
          }

          parentFormBuilder[lineItem.id].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures)));

          parentFormBuilder[lineItem.id].addControl('latitude', new FormControl(lineItem.latitude));
          parentFormBuilder[lineItem.id].addControl('longitude', new FormControl(lineItem.longitude));
        });

        return this.fb.group(parentFormBuilder);
      }
    }
  }

  calculateLineItemQuantity(form, bidItem, isInitial = false) {
    if (bidItem.rollup === true) {
      if (bidItem.rollup_quantity_editable === false) {
        const lineItemValues = form.getRawValue();

        for (const lineItemId in lineItemValues) {
          if (lineItemId in lineItemValues) {
            const lineItem = lineItemValues[lineItemId];
            const fieldValuesMap = this.getFieldValuesMap(lineItem);
            const quantity = (Parser.evaluate(bidItem.rollup_formula, fieldValuesMap)).toFixed(2);

            form.get(lineItemId)?.patchValue({ quantity }, { emitEvent: false });
          }
        }
      } else if (bidItem.rollup_quantity_editable === true) {
        const lineItemValues = form.getRawValue();

        for (const lineItemId in lineItemValues) {
          if (lineItemId in lineItemValues) {
            const lineItem = lineItemValues[lineItemId];
            const fieldValuesMap = this.getFieldValuesMap(lineItem);
            const quantity = Number((Parser.evaluate(bidItem.rollup_formula, fieldValuesMap)).toFixed(2));

            if (isInitial) {
              if (
                Number(lineItem.quantity) !== 0 &&
                quantity !== Number(lineItem.initialQuantity)
              ) {
                form.get(lineItemId)?.patchValue({ valueType: 'manual_entry' }, { emitEvent: false });
              } else {
                form.get(lineItemId)?.patchValue({ valueType: 'dynamic_rollup' }, { emitEvent: false });
                form.get(lineItemId)?.patchValue({ quantity }, { emitEvent: false });
              }
            } else {
              if ((form.get(lineItemId))['controls']?.valueType?.value === 'dynamic_rollup') {
                form.get(lineItemId)?.patchValue({ quantity }, { emitEvent: false });
              }
            }
          }
        }
      }
    }

    return form;
  }

  calculateTotalQuantity(form) {
    let temp = 0;
    const lineItemValues = form.getRawValue();

    for (const lineItemId in lineItemValues) {
      if (lineItemId in lineItemValues) {
        const lineItem = lineItemValues[lineItemId];

        temp += Number(lineItem.quantity);
      }
    }

    return temp;
  }

  getSelectedLineItemPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  addNewLineItem(newElement) {
    this.lineItems.push(newElement);

    const values = this.form.getRawValue();

    this.form = this.createLineItemForm(this.bidItem, this.lineItems);

    this.form.patchValue(values);

    this.form = this.calculateLineItemQuantity(this.form, this.bidItem, true);

    this.quantityTotal = this.calculateTotalQuantity(this.form);

    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.form = this.calculateLineItemQuantity(this.form, this.bidItem);
        this.quantityTotal = this.calculateTotalQuantity(this.form);

        this.projectsService.isAnyFormDirty.next(true);
      });
    this.selectedLineItemPictures = [];
  }

  deleteLineItem(lineItemId) {
    this.form.removeControl(lineItemId);
    this.lineItems = this.lineItems.filter(o => o.id !== lineItemId);

    if (this.selectedLineItemId === lineItemId) {
      this.selectedLineItemId = null;
    }
  }

  deletePicture(id) {
    let pictures = this.getSelectedLineItemPictures();

    pictures = pictures?.filter(o => o.id !== id);

    this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures').setValue(JSON.stringify(pictures));
    this.selectedLineItemPictures = pictures;
  }

  deleteLocation(lineItemId) {
    this.getFormGroup(this.form, lineItemId)?.get('latitude').setValue(null);
    this.getFormGroup(this.form, lineItemId)?.get('longitude').setValue(null);
  }

  showLocationSection(lineItemId) {
    return this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== '' &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== '';
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
