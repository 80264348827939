import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectsService } from '../../../../../modules/projects/core/projects.service';

@Component({
  selector: 'app-horizontal-date-picker',
  templateUrl: './horizontal-date-picker.component.html',
  styleUrls: ['./horizontal-date-picker.component.scss'],
})
export class HorizontalDatePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: any = null;
  @Input() count = 7;
  @Input() triggerChange = 0;
  @Output() onDateSelection = new EventEmitter();
  datesList = [];
  selected: false;
  form = new FormGroup({
    date: new FormControl(null),
  });
  protected readonly moment = moment;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private projectsService: ProjectsService,
  ) {
  }

  ngOnInit(): void {
    this.form.controls.date.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(date => {
        this.projectsService.closeRightPanel.next();

        if (this.checkDateNotExist(date)) {
          this.datesList = this.generatePrevDates(date, this.count);
        }

        this.onDateSelection.emit(moment(date).format('YYYY-MM-DD'));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let startDate = moment();

    if (this.date) {
      startDate = this.date;
      this.form.controls.date.setValue(startDate, { emitEvent: false });
    }

    this.datesList = this.generatePrevDates(startDate, this.count);
  }

  generatePrevDates(startDate, n) {
    const dates = [];
    for (let i = 0; i < n; i++) {
      dates.push(moment(startDate).subtract(i, 'days'));
    }
    return dates;
  }

  checkDateNotExist(dateToCheck: string): boolean {
    return !this.datesList.some(date => moment(date).isSame(moment(dateToCheck), 'day'));
  }

  selectDate(date) {
    this.form.controls.date.setValue(date);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
